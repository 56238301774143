"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.componentTokens = void 0;
exports.componentTokens = {
  'inputtext': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{inputtext.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{inputtext.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{inputtext.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'ring': {
          'stroke': {
            value: {
              "color": "{inputtext.focus.ring.color}",
              "width": "{inputtext.focus.ring.width} + {inputtext.focus.ring.offset}",
              "style": "{inputtext.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{inputtext.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{inputtext.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '0.875rem',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      },
      'font': {
        'size': {
          value: '1.125rem',
          type: 'fontSizes'
        }
      }
    }
  },
  'panel': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'header': {
      'background': {
        value: 'transparent',
        type: 'color'
      },
      'color': {
        value: '{text.color}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '0',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '1.125rem',
        type: 'spacing'
      }
    },
    'figma': {
      'header': {
        'stroke': {
          value: {
            "color": "{panel.header.border.color}",
            "width": "0",
            "style": "none"
          },
          type: 'border'
        }
      },
      'stroke': {
        value: {
          "color": "{panel.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'toggleableHeader': {
      'padding': {
        value: '0.375rem 1.125rem',
        type: 'spacing'
      }
    },
    'title': {
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      }
    },
    'content': {
      'padding': {
        value: '0 1.125rem 1.125rem 1.125rem',
        type: 'spacing'
      }
    },
    'footer': {
      'padding': {
        value: '0 1.125rem 1.125rem 1.125rem',
        type: 'spacing'
      }
    }
  },
  'button': {
    'border': {
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'rounded': {
      'border': {
        'radius': {
          value: '2rem',
          type: 'borderRadius'
        }
      }
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'icon': {
      'only': {
        'width': {
          value: '2.5rem',
          type: 'sizing'
        }
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '0.875rem',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '0.625rem',
          type: 'spacing'
        },
        'y': {
          value: '0.375rem',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '1.125rem',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '0.875rem',
          type: 'spacing'
        },
        'y': {
          value: '0.625rem',
          type: 'spacing'
        }
      }
    },
    'label': {
      'font': {
        'weight': {
          value: '500',
          type: 'fontWeights'
        }
      }
    },
    'raised': {
      'shadow': {
        value: [{
          "x": "0",
          "y": "3",
          "blur": "1",
          "spread": "-2",
          "color": "rgba(0, 0, 0, 0.2)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "2",
          "blur": "2",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.14)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "1",
          "blur": "5",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.12)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    },
    'focus': {
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        }
      }
    }
  },
  'iconfield': {
    'icon': {
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'figma': {
      'sm': {
        'icon': {
          'size': {
            value: '{inputtext.sm.font.size}',
            type: 'sizing'
          }
        }
      },
      'lg': {
        'icon': {
          'size': {
            value: '{inputtext.lg.font.size}',
            type: 'sizing'
          }
        }
      }
    }
  },
  'toggleswitch': {
    'width': {
      value: '2.5rem',
      type: 'sizing'
    },
    'height': {
      value: '1.5rem',
      type: 'sizing'
    },
    'border': {
      'radius': {
        value: '30px',
        type: 'borderRadius'
      },
      'width': {
        value: '1px',
        type: 'borderWidth'
      },
      'color': {
        value: 'transparent',
        type: 'color'
      }
    },
    'gap': {
      value: '0.25rem',
      type: 'spacing'
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'hover': {
      'border': {
        'color': {
          value: 'transparent',
          type: 'color'
        }
      }
    },
    'checked': {
      'border': {
        'color': {
          value: 'transparent',
          type: 'color'
        }
      },
      'hover': {
        'border': {
          'color': {
            value: 'transparent',
            type: 'color'
          }
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      }
    },
    'handle': {
      'border': {
        'radius': {
          value: '{toggleswitch.handle.size}/2',
          type: 'borderRadius'
        }
      },
      'size': {
        value: '1rem',
        type: 'sizing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{toggleswitch.border.color}",
          "width": "{toggleswitch.border.width}",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{toggleswitch.hover.border.color}",
            "width": "{toggleswitch.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'checked': {
        'stroke': {
          value: {
            "color": "{toggleswitch.checked.border.color}",
            "width": "{toggleswitch.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{toggleswitch.checked.hover.border.color}",
              "width": "{toggleswitch.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{toggleswitch.invalid.border.color}",
            "width": "{toggleswitch.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{toggleswitch.focus.ring.color}",
              "width": "{toggleswitch.focus.ring.width} + {toggleswitch.focus.ring.offset}",
              "style": "{toggleswitch.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{toggleswitch.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      }
    },
    'focus': {
      'ring': {
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        },
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        }
      }
    }
  },
  'float': {
    'label': {
      'color': {
        value: '{form.field.float.label.color}',
        type: 'color'
      },
      'focus': {
        'color': {
          value: '{form.field.float.label.focus.color}',
          type: 'color'
        }
      },
      'invalid': {
        'color': {
          value: '{form.field.float.label.invalid.color}',
          type: 'color'
        }
      }
    }
  },
  'radiobutton': {
    'width': {
      value: '1.25rem',
      type: 'sizing'
    },
    'height': {
      value: '1.25rem',
      type: 'sizing'
    },
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'checked': {
      'background': {
        value: '{primary.color}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{primary.hover.color}',
          type: 'color'
        },
        'border': {
          'color': {
            value: '{primary.hover.color}',
            type: 'color'
          }
        }
      },
      'border': {
        'color': {
          value: '{primary.color}',
          type: 'color'
        }
      },
      'focus': {
        'border': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        }
      },
      'disabled': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      }
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'icon': {
      'size': {
        value: '0.75rem',
        type: 'sizing'
      },
      'checked': {
        'color': {
          value: '{primary.contrast.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{primary.contrast.color}',
            type: 'color'
          }
        }
      },
      'disabled': {
        'color': {
          value: '{form.field.disabled.color}',
          type: 'color'
        }
      },
      'sm': {
        'size': {
          value: '0.5rem',
          type: 'sizing'
        }
      },
      'lg': {
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{radiobutton.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{radiobutton.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{radiobutton.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'ring': {
          'stroke': {
            value: {
              "color": "{radiobutton.focus.ring.color}",
              "width": "{radiobutton.focus.ring.width} + {radiobutton.focus.ring.offset}",
              "style": "{radiobutton.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{radiobutton.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      },
      'checked': {
        'stroke': {
          value: {
            "color": "{radiobutton.checked.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{radiobutton.checked.hover.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'focus': {
          'stroke': {
            value: {
              "color": "{radiobutton.checked.focus.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'disabled': {
          'stroke': {
            value: {
              "color": "{radiobutton.checked.disabled.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{radiobutton.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'sm': {
      'width': {
        value: '1rem',
        type: 'sizing'
      },
      'height': {
        value: '1rem',
        type: 'sizing'
      }
    },
    'lg': {
      'width': {
        value: '1.5rem',
        type: 'sizing'
      },
      'height': {
        value: '1.5rem',
        type: 'sizing'
      }
    }
  },
  'checkbox': {
    'border': {
      'radius': {
        value: '{border.radius.sm}',
        type: 'borderRadius'
      },
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      }
    },
    'width': {
      value: '1.25rem',
      type: 'sizing'
    },
    'height': {
      value: '1.25rem',
      type: 'sizing'
    },
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'checked': {
      'background': {
        value: '{primary.color}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{primary.hover.color}',
          type: 'color'
        },
        'border': {
          'color': {
            value: '{primary.hover.color}',
            type: 'color'
          }
        }
      },
      'border': {
        'color': {
          value: '{primary.color}',
          type: 'color'
        }
      },
      'focus': {
        'border': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        }
      },
      'disabled': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      }
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'icon': {
      'size': {
        value: '0.875rem',
        type: 'sizing'
      },
      'color': {
        value: '{form.field.color}',
        type: 'color'
      },
      'checked': {
        'color': {
          value: '{primary.contrast.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{primary.contrast.color}',
            type: 'color'
          }
        }
      },
      'disabled': {
        'color': {
          value: '{form.field.disabled.color}',
          type: 'color'
        }
      },
      'sm': {
        'size': {
          value: '0.75rem',
          type: 'sizing'
        }
      },
      'lg': {
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{checkbox.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{checkbox.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{checkbox.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'ring': {
          'stroke': {
            value: {
              "color": "{checkbox.focus.ring.color}",
              "width": "{checkbox.focus.ring.width} + {checkbox.focus.ring.offset}",
              "style": "{checkbox.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{checkbox.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      },
      'checked': {
        'stroke': {
          value: {
            "color": "{checkbox.checked.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{checkbox.checked.hover.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'focus': {
          'stroke': {
            value: {
              "color": "{checkbox.checked.focus.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'disabled': {
          'stroke': {
            value: {
              "color": "{checkbox.checked.disabled.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{checkbox.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'sm': {
      'width': {
        value: '1rem',
        type: 'sizing'
      },
      'height': {
        value: '1rem',
        type: 'sizing'
      }
    },
    'lg': {
      'width': {
        value: '1.5rem',
        type: 'sizing'
      },
      'height': {
        value: '1.5rem',
        type: 'sizing'
      }
    }
  },
  'autocomplete': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      }
    },
    'list': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{list.gap}',
        type: 'spacing'
      }
    },
    'option': {
      'focus': {
        'background': {
          value: '{list.option.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.focus.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{list.option.selected.background}',
          type: 'color'
        },
        'focus': {
          'background': {
            value: '{list.option.selected.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.selected.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.selected.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'padding': {
        value: '{list.option.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{list.option.border.radius}',
          type: 'borderRadius'
        }
      },
      'group': {
        'background': {
          value: '{list.option.group.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.group.color}',
          type: 'color'
        },
        'font': {
          'weight': {
            value: '{list.option.group.font.weight}',
            type: 'fontWeights'
          }
        },
        'padding': {
          value: '{list.option.group.padding}',
          type: 'spacing'
        }
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'border': {
        'color': {
          value: '{form.field.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{form.field.border.radius}',
          type: 'borderRadius'
        }
      },
      'hover': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      },
      'active': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      },
      'sm': {
        'width': {
          value: '2rem',
          type: 'sizing'
        }
      },
      'lg': {
        'width': {
          value: '3rem',
          type: 'sizing'
        }
      }
    },
    'chip': {
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      }
    },
    'empty': {
      'message': {
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        }
      }
    },
    'figma': {
      'overlay': {
        'stroke': {
          value: {
            "color": "{autocomplete.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'dropdown': {
        'stroke': {
          value: {
            "color": "{autocomplete.dropdown.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{autocomplete.dropdown.hover.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'active': {
          'stroke': {
            value: {
              "color": "{autocomplete.dropdown.active.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      },
      'chip': {
        'padding': {
          'top': {
            value: '{autocomplete.padding.y}/2',
            type: 'spacing'
          },
          'bottom': {
            value: '{autocomplete.padding.y} /2',
            type: 'spacing'
          }
        }
      },
      'input': {
        'multiple': {
          'gap': {
            value: '{autocomplete.padding.y} /2',
            type: 'spacing'
          },
          'padding': {
            'y': {
              value: '{autocomplete.padding.y} / 2',
              type: 'spacing'
            }
          },
          'haschip': {
            'padding': {
              'x': {
                value: '{autocomplete.padding.y} / 2',
                type: 'spacing'
              }
            }
          }
        },
        'chip': {
          'padding': {
            'top': {
              value: '{autocomplete.padding.y} / 2',
              type: 'spacing'
            },
            'bottom': {
              value: '{autocomplete.padding.y} / 2',
              type: 'spacing'
            }
          }
        }
      },
      'stroke': {
        value: {
          "color": "{autocomplete.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{autocomplete.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{autocomplete.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{autocomplete.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'multiple': {
        'border': {
          'radius': {
            value: '0',
            type: 'borderRadius'
          }
        }
      }
    }
  },
  'datepicker': {
    'figma': {
      'dropdown': {
        'stroke': {
          value: {
            "color": "{datepicker.dropdown.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{datepicker.dropdown.hover.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'active': {
          'stroke': {
            value: {
              "color": "{datepicker.dropdown.active.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      },
      'buttonbar': {
        'stroke': {
          value: {
            "color": "{datepicker.buttonbar.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'panel': {
        'stroke': {
          value: {
            "color": "{datepicker.panel.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'header': {
        'stroke': {
          value: {
            "color": "{datepicker.header.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'time': {
        'picker': {
          'stroke': {
            value: {
              "color": "{datepicker.time.picker.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      }
    },
    'panel': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.popover.shadow}',
        type: 'boxShadow'
      },
      'padding': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      }
    },
    'header': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0 0 0.5rem 0',
        type: 'spacing'
      }
    },
    'title': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'font': {
        'weight': {
          value: '500',
          type: 'fontWeights'
        }
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'border': {
        'color': {
          value: '{form.field.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{form.field.border.radius}',
          type: 'borderRadius'
        }
      },
      'hover': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      },
      'active': {
        'border': {
          'color': {
            value: '{form.field.border.color}',
            type: 'color'
          }
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      },
      'sm': {
        'width': {
          value: '2rem',
          type: 'sizing'
        }
      },
      'lg': {
        'width': {
          value: '3rem',
          type: 'sizing'
        }
      }
    },
    'input': {
      'icon': {
        'color': {
          value: '{form.field.icon.color}',
          type: 'color'
        }
      }
    },
    'select': {
      'month': {
        'hover': {
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          },
          'color': {
            value: '{content.hover.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'padding': {
          value: '0.25rem 0.5rem',
          type: 'spacing'
        },
        'border': {
          'radius': {
            value: '{content.border.radius}',
            type: 'borderRadius'
          }
        }
      },
      'year': {
        'hover': {
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          },
          'color': {
            value: '{content.hover.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'padding': {
          value: '0.25rem 0.5rem',
          type: 'spacing'
        },
        'border': {
          'radius': {
            value: '{content.border.radius}',
            type: 'borderRadius'
          }
        }
      }
    },
    'group': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'gap': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      }
    },
    'day': {
      'view': {
        'margin': {
          value: '0.5rem 0 0 0',
          type: 'spacing'
        }
      }
    },
    'week': {
      'day': {
        'padding': {
          value: '0.25rem',
          type: 'spacing'
        },
        'font': {
          'weight': {
            value: '500',
            type: 'fontWeights'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        }
      }
    },
    'date': {
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{primary.color}',
          type: 'color'
        },
        'color': {
          value: '{primary.contrast.color}',
          type: 'color'
        }
      },
      'range': {
        'selected': {
          'background': {
            value: '{highlight.background}',
            type: 'color'
          },
          'color': {
            value: '{highlight.color}',
            type: 'color'
          }
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'width': {
        value: '2rem',
        type: 'sizing'
      },
      'height': {
        value: '2rem',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{datepicker.date.width} / 2',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.25rem',
        type: 'spacing'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'month': {
      'view': {
        'margin': {
          value: '0.5rem 0 0 0',
          type: 'spacing'
        }
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.375rem',
        type: 'spacing'
      }
    },
    'year': {
      'view': {
        'margin': {
          value: '0.5rem 0 0 0',
          type: 'spacing'
        }
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.375rem',
        type: 'spacing'
      }
    },
    'buttonbar': {
      'padding': {
        value: '0.5rem 0 0 0',
        type: 'spacing'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    },
    'time': {
      'picker': {
        'padding': {
          value: '0.5rem 0 0 0',
          type: 'spacing'
        },
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        },
        'button': {
          'gap': {
            value: '0.25rem',
            type: 'spacing'
          }
        }
      }
    }
  },
  'rating': {
    'gap': {
      value: '0.25rem',
      type: 'spacing'
    },
    'icon': {
      'size': {
        value: '1rem',
        type: 'sizing'
      }
    },
    'color': {
      value: '{text.muted.color}',
      type: 'color'
    },
    'hover': {
      'color': {
        value: '{primary.color}',
        type: 'color'
      }
    },
    'active': {
      'color': {
        value: '{primary.color}',
        type: 'color'
      }
    },
    'focus': {
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{rating.focus.ring.color}",
              "width": "{rating.focus.ring.width} + {rating.focus.ring.offset}",
              "style": "{rating.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{rating.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      }
    }
  },
  'avatar': {
    'width': {
      value: '2rem',
      type: 'sizing'
    },
    'height': {
      value: '2rem',
      type: 'sizing'
    },
    'font': {
      'size': {
        value: '1rem',
        type: 'fontSizes'
      }
    },
    'background': {
      value: '{content.border.color}',
      type: 'color'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'group': {
      'border': {
        'color': {
          value: '{content.background}',
          type: 'color'
        }
      },
      'offset': {
        value: '-0.75rem',
        type: 'spacing'
      },
      'stroke': {
        value: {
          "color": "{avatar.group.border.color}",
          "width": "2",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'lg': {
      'width': {
        value: '3rem',
        type: 'sizing'
      },
      'height': {
        value: '3rem',
        type: 'sizing'
      },
      'font': {
        'size': {
          value: '1.5rem',
          type: 'fontSizes'
        }
      },
      'icon': {
        'size': {
          value: '1.5rem',
          type: 'sizing'
        }
      },
      'group': {
        'offset': {
          value: '-1rem',
          type: 'spacing'
        }
      }
    },
    'xl': {
      'width': {
        value: '4rem',
        type: 'sizing'
      },
      'height': {
        value: '4rem',
        type: 'sizing'
      },
      'font': {
        'size': {
          value: '2rem',
          type: 'fontSizes'
        }
      },
      'icon': {
        'size': {
          value: '2rem',
          type: 'sizing'
        }
      },
      'group': {
        'offset': {
          value: '-1.5rem',
          type: 'spacing'
        }
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'figma': {
      'circle': {
        'border': {
          'radius': {
            value: '{avatar.width} / 2',
            type: 'borderRadius'
          }
        },
        'lg': {
          'border': {
            'radius': {
              value: '{avatar.lg.width} / 2',
              type: 'borderRadius'
            }
          }
        },
        'xl': {
          'border': {
            'radius': {
              value: '{avatar.xl.width} / 2',
              type: 'borderRadius'
            }
          }
        }
      }
    },
    'icon': {
      'size': {
        value: '1rem',
        type: 'sizing'
      }
    }
  },
  'badge': {
    'border': {
      'radius': {
        value: '{border.radius.md}',
        type: 'borderRadius'
      }
    },
    'padding': {
      value: '0 0.5rem',
      type: 'spacing'
    },
    'font': {
      'size': {
        value: '0.75rem',
        type: 'fontSizes'
      },
      'weight': {
        value: '700',
        type: 'fontWeights'
      }
    },
    'min': {
      'width': {
        value: '1.5rem',
        type: 'sizing'
      }
    },
    'height': {
      value: '1.5rem',
      type: 'sizing'
    },
    'dot': {
      'size': {
        value: '0.5rem',
        type: 'sizing'
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '0.625rem',
          type: 'fontSizes'
        }
      },
      'min': {
        'width': {
          value: '1.25rem',
          type: 'sizing'
        }
      },
      'height': {
        value: '1.25rem',
        type: 'sizing'
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '0.875rem',
          type: 'fontSizes'
        }
      },
      'min': {
        'width': {
          value: '1.75rem',
          type: 'sizing'
        }
      },
      'height': {
        value: '1.75rem',
        type: 'sizing'
      }
    },
    'xl': {
      'font': {
        'size': {
          value: '1rem',
          type: 'fontSizes'
        }
      },
      'min': {
        'width': {
          value: '2rem',
          type: 'sizing'
        }
      },
      'height': {
        value: '2rem',
        type: 'sizing'
      }
    }
  },
  'chip': {
    'border': {
      'radius': {
        value: '16px',
        type: 'borderRadius'
      }
    },
    'padding': {
      'x': {
        value: '0.75rem',
        type: 'spacing'
      },
      'y': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'image': {
      'width': {
        value: '2rem',
        type: 'sizing'
      },
      'height': {
        value: '2rem',
        type: 'sizing'
      }
    },
    'icon': {
      'size': {
        value: '1rem',
        type: 'sizing'
      }
    },
    'remove': {
      'icon': {
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            }
          }
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        },
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      }
    },
    'figma': {
      'remove': {
        'icon': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{chip.remove.icon.focus.ring.color}",
                  "width": "{chip.remove.icon.focus.ring.width} + {chip.remove.icon.focus.ring.offset}",
                  "style": "{chip.remove.icon.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{chip.background}",
                    "width": "{chip.remove.icon.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      },
      'image': {
        'padding': {
          'left': {
            value: '{chip.padding.x} - {chip.padding.y}',
            type: 'spacing'
          },
          'y': {
            value: '{chip.padding.y}/2',
            type: 'spacing'
          }
        }
      }
    }
  },
  'progressbar': {
    'background': {
      value: '{content.border.color}',
      type: 'color'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'value': {
      'background': {
        value: '{primary.color}',
        type: 'color'
      }
    },
    'label': {
      'color': {
        value: '{primary.contrast.color}',
        type: 'color'
      },
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      }
    },
    'font': {
      'size': {
        value: '0.75rem',
        type: 'fontSizes'
      }
    },
    'height': {
      value: '1.25rem',
      type: 'sizing'
    }
  },
  'message': {
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      },
      'width': {
        value: '1px',
        type: 'borderWidth'
      }
    },
    'content': {
      'padding': {
        value: '0.5rem 0.75rem',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'sm': {
        'padding': {
          value: '0.375rem 0.625rem',
          type: 'spacing'
        }
      },
      'lg': {
        'padding': {
          value: '0.625rem 0.875rem',
          type: 'spacing'
        }
      }
    },
    'text': {
      'font': {
        'size': {
          value: '1rem',
          type: 'fontSizes'
        },
        'weight': {
          value: '500',
          type: 'fontWeights'
        }
      },
      'sm': {
        'font': {
          'size': {
            value: '0.875rem',
            type: 'fontSizes'
          }
        }
      },
      'lg': {
        'font': {
          'size': {
            value: '1.125rem',
            type: 'fontSizes'
          }
        }
      }
    },
    'icon': {
      'size': {
        value: '1.125rem',
        type: 'sizing'
      },
      'sm': {
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      },
      'lg': {
        'size': {
          value: '1.25rem',
          type: 'sizing'
        }
      }
    },
    'close': {
      'button': {
        'width': {
          value: '1.75rem',
          type: 'sizing'
        },
        'height': {
          value: '1.75rem',
          type: 'sizing'
        },
        'border': {
          'radius': {
            value: '{message.close.button.width} / 2',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            }
          }
        }
      },
      'icon': {
        'size': {
          value: '1rem',
          type: 'sizing'
        },
        'sm': {
          'size': {
            value: '0.875rem',
            type: 'sizing'
          }
        },
        'lg': {
          'size': {
            value: '1.125rem',
            type: 'sizing'
          }
        }
      }
    },
    'outlined': {
      'border': {
        'width': {
          value: '1px',
          type: 'borderWidth'
        }
      }
    },
    'simple': {
      'content': {
        'padding': {
          value: '0',
          type: 'spacing'
        }
      }
    }
  },
  'card': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'radius': {
        value: '{border.radius.xl}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'shadow': {
      value: [{
        "x": "0",
        "y": "1",
        "blur": "3",
        "spread": "0",
        "color": "rgba(0, 0, 0, 0.1)",
        "type": "dropShadow"
      }, {
        "x": "0",
        "y": "1",
        "blur": "2",
        "spread": "-1",
        "color": "rgba(0, 0, 0, 0.1)",
        "type": "dropShadow"
      }],
      type: 'boxShadow'
    },
    'body': {
      'padding': {
        value: '1.25rem',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'caption': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'title': {
      'font': {
        'size': {
          value: '1.25rem',
          type: 'fontSizes'
        },
        'weight': {
          value: '500',
          type: 'fontWeights'
        }
      }
    },
    'subtitle': {
      'color': {
        value: '{text.muted.color}',
        type: 'color'
      }
    }
  },
  'tag': {
    'font': {
      'size': {
        value: '0.875rem',
        type: 'fontSizes'
      },
      'weight': {
        value: '700',
        type: 'fontWeights'
      }
    },
    'padding': {
      value: '0.25rem 0.5rem',
      type: 'spacing'
    },
    'gap': {
      value: '0.25rem',
      type: 'spacing'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'rounded': {
      'border': {
        'radius': {
          value: '{border.radius.xl}',
          type: 'borderRadius'
        }
      }
    },
    'icon': {
      'size': {
        value: '0.75rem',
        type: 'sizing'
      }
    }
  },
  'skeleton': {
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    }
  },
  'breadcrumb': {
    'padding': {
      value: '1rem',
      type: 'spacing'
    },
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'item': {
      'color': {
        value: '{text.muted.color}',
        type: 'color'
      },
      'hover': {
        'color': {
          value: '{text.color}',
          type: 'color'
        }
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        }
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'separator': {
      'color': {
        value: '{navigation.item.icon.color}',
        type: 'color'
      }
    },
    'figma': {
      'item': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{breadcrumb.item.focus.ring.color}",
                "width": "{breadcrumb.item.focus.ring.width} + {breadcrumb.item.focus.ring.offset}",
                "style": "{breadcrumb.item.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{breadcrumb.item.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'drawer': {
    'background': {
      value: '{overlay.modal.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{overlay.modal.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{overlay.modal.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{overlay.modal.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.modal.shadow}',
      type: 'boxShadow'
    },
    'header': {
      'padding': {
        value: '{overlay.modal.padding}',
        type: 'spacing'
      }
    },
    'title': {
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        },
        'size': {
          value: '1.5rem',
          type: 'fontSizes'
        }
      }
    },
    'content': {
      'padding': {
        value: '0 {overlay.modal.padding} {overlay.modal.padding} {overlay.modal.padding}',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{drawer.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    }
  },
  'overlay': {
    'title': {
      'font': {
        'size': {
          value: '1.5rem',
          type: 'fontSizes'
        }
      }
    }
  },
  'cascadeselect': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{cascadeselect.focus.ring.color}",
              "width": "{cascadeselect.focus.ring.width} + {cascadeselect.focus.ring.offset}",
              "style": "{cascadeselect.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{cascadeselect.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        },
        'stroke': {
          value: {
            "color": "{cascadeselect.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'stroke': {
        value: {
          "color": "{cascadeselect.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{cascadeselect.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{cascadeselect.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'overlay': {
        'stroke': {
          value: {
            "color": "{cascadeselect.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      }
    },
    'list': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{list.gap}',
        type: 'spacing'
      },
      'mobile': {
        'indent': {
          value: '1rem',
          type: 'spacing'
        }
      }
    },
    'option': {
      'focus': {
        'background': {
          value: '{list.option.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.focus.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{list.option.selected.background}',
          type: 'color'
        },
        'focus': {
          'background': {
            value: '{list.option.selected.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.selected.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.selected.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'padding': {
        value: '{list.option.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{list.option.border.radius}',
          type: 'borderRadius'
        }
      },
      'icon': {
        'color': {
          value: '{list.option.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{list.option.icon.focus.color}',
            type: 'color'
          }
        },
        'size': {
          value: '0.875rem',
          type: 'sizing'
        }
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      }
    },
    'clear': {
      'icon': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    }
  },
  'colorpicker': {
    'preview': {
      'width': {
        value: '1.5rem',
        type: 'sizing'
      },
      'height': {
        value: '1.5rem',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{form.field.border.radius}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'panel': {
      'border': {
        'radius': {
          value: '{overlay.popover.border.radius}',
          type: 'borderRadius'
        }
      },
      'shadow': {
        value: '{overlay.popover.shadow}',
        type: 'boxShadow'
      }
    },
    'figma': {
      'preview': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{colorpicker.preview.focus.ring.color}",
                "width": "{colorpicker.preview.focus.ring.width} + {colorpicker.preview.focus.ring.offset}",
                "style": "{colorpicker.preview.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{colorpicker.preview.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'select': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      }
    },
    'list': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{list.gap}',
        type: 'spacing'
      },
      'header': {
        'padding': {
          value: '{list.header.padding}',
          type: 'spacing'
        }
      }
    },
    'option': {
      'focus': {
        'background': {
          value: '{list.option.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.focus.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{list.option.selected.background}',
          type: 'color'
        },
        'focus': {
          'background': {
            value: '{list.option.selected.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.selected.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.selected.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'padding': {
        value: '{list.option.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{list.option.border.radius}',
          type: 'borderRadius'
        }
      },
      'group': {
        'background': {
          value: '{list.option.group.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.group.color}',
          type: 'color'
        },
        'font': {
          'weight': {
            value: '{list.option.group.font.weight}',
            type: 'fontWeights'
          }
        },
        'padding': {
          value: '{list.option.group.padding}',
          type: 'spacing'
        }
      }
    },
    'clear': {
      'icon': {
        'color': {
          value: '{form.field.icon.color}',
          type: 'color'
        }
      }
    },
    'checkmark': {
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'gutter': {
        'start': {
          value: '-0.375rem',
          type: 'spacing'
        },
        'end': {
          value: '0.375rem',
          type: 'spacing'
        }
      }
    },
    'empty': {
      'message': {
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        }
      }
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{select.focus.ring.color}",
              "width": "{select.focus.ring.width} + {cascadeselect.focus.ring.offset}",
              "style": "{select.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{select.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        },
        'stroke': {
          value: {
            "color": "{select.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'stroke': {
        value: {
          "color": "{select.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{select.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{select.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'overlay': {
        'stroke': {
          value: {
            "color": "{select.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      }
    }
  },
  'editor': {
    'toolbar': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'item': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        }
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      },
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'option': {
        'focus': {
          'background': {
            value: '{list.option.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.color}',
          type: 'color'
        },
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        },
        'border': {
          'radius': {
            value: '{list.option.border.radius}',
            type: 'borderRadius'
          }
        }
      }
    },
    'content': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      }
    },
    'figma': {
      'overlay': {
        'stroke': {
          value: {
            "color": "{editor.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'toolbar': {
        'stroke': {
          value: {
            "color": "{editor.toolbar.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'content': {
        'stroke': {
          value: {
            "color": "{editor.content.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'inputgroup': {
    'addon': {
      'background': {
        value: '{form.field.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{form.field.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{form.field.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      },
      'padding': {
        value: '0.5rem',
        type: 'spacing'
      },
      'min': {
        'width': {
          value: '2.5rem',
          type: 'sizing'
        }
      }
    },
    'figma': {
      'addon': {
        'stroke': {
          value: {
            "color": "{inputgroup.addon.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'textarea': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{textarea.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{textarea.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{textarea.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{textarea.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'sm': {
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      },
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      }
    },
    'lg': {
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      },
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      }
    }
  },
  'inputnumber': {
    'button': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{form.field.border.radius}',
          type: 'borderRadius'
        }
      },
      'vertical': {
        'padding': {
          value: '{form.field.padding.y}',
          type: 'spacing'
        }
      }
    }
  },
  'knob': {
    'focus': {
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{knob.focus.ring.color}",
              "width": "{knob.focus.ring.width} + {knob.focus.ring.offset}",
              "style": "{knob.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{knob.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      }
    },
    'value': {
      'background': {
        value: '{primary.color}',
        type: 'color'
      }
    },
    'range': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      }
    },
    'text': {
      'color': {
        value: '{text.muted.color}',
        type: 'color'
      }
    }
  },
  'listbox': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{listbox.focus.ring.color}",
              "width": "{listbox.focus.ring.width} + {listbox.focus.ring.offset}",
              "style": "{listbox.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{listbox.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        },
        'stroke': {
          value: {
            "color": "{listbox.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'stroke': {
        value: {
          "color": "{listbox.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{listbox.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{listbox.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'list': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{list.gap}',
        type: 'spacing'
      },
      'header': {
        'padding': {
          value: '{list.header.padding}',
          type: 'spacing'
        }
      }
    },
    'option': {
      'focus': {
        'background': {
          value: '{list.option.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.focus.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{list.option.selected.background}',
          type: 'color'
        },
        'focus': {
          'background': {
            value: '{list.option.selected.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.selected.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.selected.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'padding': {
        value: '{list.option.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{list.option.border.radius}',
          type: 'borderRadius'
        }
      },
      'group': {
        'background': {
          value: '{list.option.group.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.group.color}',
          type: 'color'
        },
        'font': {
          'weight': {
            value: '{list.option.group.font.weight}',
            type: 'fontWeights'
          }
        },
        'padding': {
          value: '{list.option.group.padding}',
          type: 'spacing'
        }
      }
    },
    'checkmark': {
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'gutter': {
        'start': {
          value: '-0.375rem',
          type: 'spacing'
        },
        'end': {
          value: '0.375rem',
          type: 'spacing'
        }
      }
    },
    'empty': {
      'message': {
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        }
      }
    }
  },
  'multiselect': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      }
    },
    'list': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{list.gap}',
        type: 'spacing'
      },
      'header': {
        'padding': {
          value: '{list.header.padding}',
          type: 'spacing'
        }
      }
    },
    'header': {
      'padding': {
        value: '{list.header.padding}',
        type: 'spacing'
      }
    },
    'option': {
      'focus': {
        'background': {
          value: '{list.option.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.focus.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{list.option.selected.background}',
          type: 'color'
        },
        'focus': {
          'background': {
            value: '{list.option.selected.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.selected.focus.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.selected.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{list.option.color}',
        type: 'color'
      },
      'padding': {
        value: '{list.option.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{list.option.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'group': {
        'background': {
          value: '{list.option.group.background}',
          type: 'color'
        },
        'color': {
          value: '{list.option.group.color}',
          type: 'color'
        },
        'font': {
          'weight': {
            value: '{list.option.group.font.weight}',
            type: 'fontWeights'
          }
        },
        'padding': {
          value: '{list.option.group.padding}',
          type: 'spacing'
        }
      }
    },
    'chip': {
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      }
    },
    'empty': {
      'message': {
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{multiselect.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{multiselect.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{multiselect.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{multiselect.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'overlay': {
        'stroke': {
          value: {
            "color": "{multiselect.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'chip': {
        'padding': {
          'top': {
            value: '{multiselect.padding.y}/2',
            type: 'spacing'
          },
          'bottom': {
            value: '{multiselect.padding.y} /2',
            type: 'spacing'
          }
        }
      },
      'input': {
        'multiple': {
          'gap': {
            value: '{multiselect.padding.y} /2',
            type: 'spacing'
          }
        }
      }
    },
    'sm': {
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      },
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      }
    },
    'lg': {
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      },
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      }
    },
    'clear': {
      'icon': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    }
  },
  'password': {
    'meter': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'height': {
        value: '0.75rem',
        type: 'sizing'
      }
    },
    'icon': {
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.popover.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.popover.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.popover.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.popover.color}',
        type: 'color'
      },
      'padding': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      },
      'shadow': {
        value: '{overlay.popover.shadow}',
        type: 'boxShadow'
      }
    },
    'content': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'figma': {
      'overlay': {
        'stroke': {
          value: {
            "color": "{password.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'slider': {
    'track': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'size': {
        value: '3px',
        type: 'sizing'
      }
    },
    'range': {
      'background': {
        value: '{primary.color}',
        type: 'color'
      }
    },
    'handle': {
      'width': {
        value: '20px',
        type: 'sizing'
      },
      'height': {
        value: '20px',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{slider.handle.width} / 2',
          type: 'borderRadius'
        }
      },
      'background': {
        value: '{content.border.color}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'content': {
        'hover': {
          'background': {
            value: '{content.background}',
            type: 'color'
          }
        },
        'width': {
          value: '16px',
          type: 'sizing'
        },
        'height': {
          value: '16px',
          type: 'sizing'
        },
        'border': {
          'radius': {
            value: '{slider.handle.content.width} / 2',
            type: 'borderRadius'
          }
        },
        'shadow': {
          value: [{
            "x": "0",
            "y": "0.5",
            "blur": "0",
            "spread": "0",
            "color": "rgba(0, 0, 0, 0.08)",
            "type": "dropShadow"
          }, {
            "x": "0",
            "y": "1",
            "blur": "1",
            "spread": "0",
            "color": "rgba(0, 0, 0, 0.14)",
            "type": "dropShadow"
          }],
          type: 'boxShadow'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'figma': {
      'handle': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{slider.handle.focus.ring.color}",
                "width": "{slider.handle.focus.ring.width}  + {slider.handle.focus.ring.offset}",
                "style": "{slider.handle.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{slider.handle.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'togglebutton': {
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'font': {
      'weight': {
        value: '500',
        type: 'fontWeights'
      }
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{form.field.disabled.background}',
          type: 'color'
        }
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'figma': {
      'focus': {
        'ring': {
          'stroke': {
            value: {
              "color": "{togglebutton.focus.ring.color}",
              "width": "{togglebutton.focus.ring.width} + {togglebutton.focus.ring.offset}",
              "style": "{togglebutton.focus.ring.style}"
            },
            type: 'border'
          },
          'offset': {
            'stroke': {
              value: {
                "color": "{app.card.background}",
                "width": "{togglebutton.focus.ring.offset}",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      },
      'disabled': {
        'stroke': {
          value: {
            "color": "{togglebutton.disabled.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{togglebutton.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'content': {
        'padding': {
          'x': {
            value: '{togglebutton.padding.x} - {togglebutton.content.left}',
            type: 'spacing'
          },
          'y': {
            value: '{togglebutton.padding.y} - {togglebutton.content.top}',
            type: 'spacing'
          }
        },
        'lg': {
          'padding': {
            'x': {
              value: '{togglebutton.lg.padding.x} - {togglebutton.content.left}',
              type: 'spacing'
            },
            'y': {
              value: '{togglebutton.lg.padding.y} - {togglebutton.content.top}',
              type: 'spacing'
            }
          }
        },
        'sm': {
          'padding': {
            'x': {
              value: '{togglebutton.sm.padding.x} - {togglebutton.content.left}',
              type: 'spacing'
            },
            'y': {
              value: '{togglebutton.sm.padding.y} - {togglebutton.content.top}',
              type: 'spacing'
            }
          }
        }
      },
      'padding': {
        'x': {
          value: '{togglebutton.padding.x} - {togglebutton.figma.content.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{togglebutton.padding.y} - {togglebutton.figma.content.padding.y}',
          type: 'spacing'
        }
      },
      'lg': {
        'padding': {
          'x': {
            value: '{togglebutton.lg.padding.x} - {togglebutton.figma.content.lg.padding.x}',
            type: 'spacing'
          },
          'y': {
            value: '{togglebutton.lg.padding.y} - {togglebutton.figma.content.lg.padding.y}',
            type: 'spacing'
          }
        }
      },
      'sm': {
        'padding': {
          'x': {
            value: '{togglebutton.sm.padding.x} - {togglebutton.figma.content.sm.padding.x}',
            type: 'spacing'
          },
          'y': {
            value: '{togglebutton.sm.padding.y} - {togglebutton.figma.content.sm.padding.y}',
            type: 'spacing'
          }
        }
      }
    },
    'icon': {
      'disabled': {
        'color': {
          value: '{form.field.disabled.color}',
          type: 'color'
        }
      }
    },
    'content': {
      'left': {
        value: '0.25rem',
        type: 'spacing'
      },
      'top': {
        value: '0.25rem',
        type: 'spacing'
      },
      'shadow': {
        value: [{
          "x": "0",
          "y": "1",
          "blur": "2",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.02)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "1",
          "blur": "2",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.04)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    },
    'padding': {
      'x': {
        value: '1rem',
        type: 'spacing'
      },
      'y': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '0.75rem',
          type: 'spacing'
        },
        'y': {
          value: '0.375rem',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '1.25rem',
          type: 'spacing'
        },
        'y': {
          value: '0.625rem',
          type: 'spacing'
        }
      }
    }
  },
  'selectbutton': {
    'border': {
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    }
  },
  'treeselect': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'disabled': {
      'background': {
        value: '{form.field.disabled.background}',
        type: 'color'
      },
      'color': {
        value: '{form.field.disabled.color}',
        type: 'color'
      }
    },
    'filled': {
      'background': {
        value: '{form.field.filled.background}',
        type: 'color'
      },
      'focus': {
        'background': {
          value: '{form.field.filled.focus.background}',
          type: 'color'
        }
      },
      'hover': {
        'background': {
          value: '{form.field.filled.hover.background}',
          type: 'color'
        }
      }
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'hover': {
      'border': {
        'color': {
          value: '{form.field.hover.border.color}',
          type: 'color'
        }
      }
    },
    'focus': {
      'border': {
        'color': {
          value: '{form.field.focus.border.color}',
          type: 'color'
        }
      },
      'ring': {
        'width': {
          value: '{form.field.focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{form.field.focus.ring.offset}',
          type: 'borderWidth'
        },
        'color': {
          value: '{form.field.focus.ring.color}',
          type: 'color'
        },
        'style': {
          value: '{form.field.focus.ring.style}',
          type: 'other'
        },
        'shadow': {
          value: '{form.field.focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'invalid': {
      'border': {
        'color': {
          value: '{form.field.invalid.border.color}',
          type: 'color'
        }
      },
      'placeholder': {
        'color': {
          value: '{form.field.invalid.placeholder.color}',
          type: 'color'
        }
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'placeholder': {
      'color': {
        value: '{form.field.placeholder.color}',
        type: 'color'
      }
    },
    'shadow': {
      value: '{form.field.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      'x': {
        value: '{form.field.padding.x}',
        type: 'spacing'
      },
      'y': {
        value: '{form.field.padding.y}',
        type: 'spacing'
      }
    },
    'dropdown': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'color': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    },
    'overlay': {
      'background': {
        value: '{overlay.select.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{overlay.select.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{overlay.select.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{overlay.select.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.select.shadow}',
        type: 'boxShadow'
      }
    },
    'tree': {
      'padding': {
        value: '{list.padding}',
        type: 'spacing'
      }
    },
    'empty': {
      'message': {
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        }
      }
    },
    'chip': {
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{treeselect.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'hover': {
        'stroke': {
          value: {
            "color": "{treeselect.hover.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'focus': {
        'stroke': {
          value: {
            "color": "{treeselect.focus.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'invalid': {
        'stroke': {
          value: {
            "color": "{treeselect.invalid.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'overlay': {
        'stroke': {
          value: {
            "color": "{treeselect.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'chip': {
        'padding': {
          'top': {
            value: '{treeselect.padding.y}/2',
            type: 'spacing'
          },
          'bottom': {
            value: '{treeselect.padding.y} /2',
            type: 'spacing'
          }
        }
      },
      'input': {
        'multiple': {
          'gap': {
            value: '{treeselect.padding.y} /2',
            type: 'spacing'
          }
        }
      }
    },
    'sm': {
      'font': {
        'size': {
          value: '{form.field.sm.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.sm.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.sm.padding.y}',
          type: 'spacing'
        }
      }
    },
    'lg': {
      'font': {
        'size': {
          value: '{form.field.lg.font.size}',
          type: 'fontSizes'
        }
      },
      'padding': {
        'x': {
          value: '{form.field.lg.padding.x}',
          type: 'spacing'
        },
        'y': {
          value: '{form.field.lg.padding.y}',
          type: 'spacing'
        }
      }
    },
    'clear': {
      'icon': {
        value: '{form.field.icon.color}',
        type: 'color'
      }
    }
  },
  'dataview': {
    'border': {
      'color': {
        value: 'transparent',
        type: 'color'
      },
      'width': {
        value: '0',
        type: 'borderWidth'
      },
      'radius': {
        value: '0',
        type: 'borderRadius'
      }
    },
    'padding': {
      value: '0',
      type: 'spacing'
    },
    'header': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'width': {
          value: '1',
          type: 'borderWidth'
        },
        'radius': {
          value: '0',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      }
    },
    'content': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'border': {
        'color': {
          value: 'transparent',
          type: 'color'
        },
        'radius': {
          value: '0',
          type: 'borderRadius'
        },
        'width': {
          value: '0',
          type: 'borderWidth'
        }
      },
      'padding': {
        value: '0',
        type: 'spacing'
      }
    },
    'footer': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'width': {
          value: '1',
          type: 'borderWidth'
        },
        'radius': {
          value: '0',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      }
    },
    'paginator': {
      'top': {
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          },
          'width': {
            value: '1',
            type: 'borderWidth'
          }
        }
      },
      'bottom': {
        'border': {
          'width': {
            value: '1',
            type: 'borderWidth'
          },
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{dataview.border.color}",
          "width": "{dataview.border.width}",
          "style": "solid"
        },
        type: 'border'
      },
      'header': {
        'stroke': {
          value: {
            "color": "{dataview.header.border.color}",
            "width": "{dataview.header.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'content': {
        'stroke': {
          value: {
            "color": "{dataview.content.border.color}",
            "width": "{dataview.content.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'accordion': {
    'panel': {
      'border': {
        'width': {
          value: '1',
          type: 'borderWidth'
        },
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    },
    'header': {
      'color': {
        value: '{text.muted.color}',
        type: 'color'
      },
      'hover': {
        'color': {
          value: '{text.color}',
          type: 'color'
        },
        'background': {
          value: '{content.background}',
          type: 'color'
        }
      },
      'active': {
        'color': {
          value: '{text.color}',
          type: 'color'
        },
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'hover': {
          'background': {
            value: '{content.background}',
            type: 'color'
          }
        }
      },
      'padding': {
        value: '1.125rem',
        type: 'spacing'
      },
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      },
      'border': {
        'radius': {
          value: '0',
          type: 'borderRadius'
        },
        'width': {
          value: '0',
          type: 'borderWidth'
        },
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      },
      'toggle': {
        'icon': {
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          },
          'hover': {
            'color': {
              value: '{text.color}',
              type: 'color'
            }
          },
          'active': {
            'color': {
              value: '{text.color}',
              type: 'color'
            },
            'hover': {
              'color': {
                value: '{text.color}',
                type: 'color'
              }
            }
          }
        }
      },
      'first': {
        'top': {
          'border': {
            'radius': {
              value: '{content.border.radius}',
              type: 'borderRadius'
            }
          }
        },
        'border': {
          'width': {
            value: '0',
            type: 'borderWidth'
          }
        }
      },
      'last': {
        'bottom': {
          'border': {
            'radius': {
              value: '{content.border.radius}',
              type: 'borderRadius'
            }
          }
        },
        'active': {
          'bottom': {
            'border': {
              'radius': {
                value: '0',
                type: 'borderRadius'
              }
            }
          }
        }
      }
    },
    'content': {
      'border': {
        'width': {
          value: '0',
          type: 'borderWidth'
        },
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{text.color}',
        type: 'color'
      },
      'padding': {
        value: '0 1.125rem 1.125rem 1.125rem',
        type: 'spacing'
      }
    },
    'figma': {
      'panel': {
        'stroke': {
          value: {
            "color": "{accordion.panel.border.color}",
            "width": "{accordion.panel.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'header': {
        'stroke': {
          value: {
            "color": "{accordion.header.border.color}",
            "width": "{accordion.header.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{accordion.header.focus.ring.color}",
                "width": "{accordion.header.focus.ring.width} + {accordion.header.focus.ring.offset}",
                "style": "{accordion.header.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{accordion.header.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      },
      'content': {
        'stroke': {
          value: {
            "color": "{accordion.content.border.color}",
            "width": "{accordion.content.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'divider': {
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      }
    },
    'content': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{text.color}',
        type: 'color'
      }
    },
    'horizontal': {
      'margin': {
        value: '1rem 0',
        type: 'spacing'
      },
      'padding': {
        value: '0',
        type: 'spacing'
      },
      'content': {
        'padding': {
          value: '0 0.5rem',
          type: 'spacing'
        }
      }
    },
    'vertical': {
      'margin': {
        value: '0 1rem',
        type: 'spacing'
      },
      'padding': {
        value: '0',
        type: 'spacing'
      },
      'content': {
        'padding': {
          value: '0.5rem 0',
          type: 'spacing'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{divider.border.color}",
          "width": "1",
          "style": "dashed"
        },
        type: 'border'
      }
    }
  },
  'fieldset': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'padding': {
      value: '1.125rem 1.125rem 1.125rem 1.125rem',
      type: 'spacing'
    },
    'legend': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        },
        'width': {
          value: '1',
          type: 'borderWidth'
        },
        'color': {
          value: 'transparent',
          type: 'color'
        }
      },
      'padding': {
        value: '0.5rem 0.75rem',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'toggle': {
      'icon': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          }
        }
      }
    },
    'content': {
      'padding': {
        value: '0',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{fieldset.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'legend': {
        'stroke': {
          value: {
            "color": "{fieldset.legend.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'splitter': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'gutter': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      }
    },
    'handle': {
      'size': {
        value: '24px',
        type: 'sizing'
      },
      'background': {
        value: 'transparent',
        type: 'color'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          }
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{splitter.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'handle': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{splitter.handle.focus.ring.color}",
                "width": "{splitter.handle.focus.ring.width} + {splitter.handle.focus.ring.offset}",
                "style": "{splitter.handle.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{splitter.handle.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'scrollpanel': {
    'bar': {
      'size': {
        value: '9px',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'figma': {
      'bar': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{scrollpanel.bar.focus.ring.color}",
                "width": "{scrollpanel.bar.focus.ring.width} + {scrollpanel.bar.focus.ring.offset}",
                "style": "{scrollpanel.bar.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{scrollpanel.bar.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'tabs': {
    'tablist': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'width': {
          value: '1',
          type: 'borderWidth'
        }
      }
    },
    'tab': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'width': {
          value: '1',
          type: 'borderWidth'
        }
      },
      'active': {
        'border': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{primary.color}',
          type: 'color'
        },
        'background': {
          value: 'transparent',
          type: 'color'
        }
      },
      'color': {
        value: '{text.muted.color}',
        type: 'color'
      },
      'hover': {
        'color': {
          value: '{text.color}',
          type: 'color'
        },
        'background': {
          value: 'transparent',
          type: 'color'
        },
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        }
      },
      'background': {
        value: 'transparent',
        type: 'color'
      },
      'padding': {
        value: '1rem 1.125rem',
        type: 'spacing'
      },
      'font': {
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      },
      'margin': {
        value: '0 0 -1px 0',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '-1',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'tabpanel': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.875rem 1.125rem 1.125rem 1.125rem',
        type: 'spacing'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'nav': {
      'button': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.color}',
            type: 'color'
          }
        },
        'width': {
          value: '2.5rem',
          type: 'sizing'
        },
        'focus': {
          'ring': {
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '-1',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'figma': {
      'tablist': {
        'stroke': {
          value: {
            "color": "{tabs.tablist.border.color}",
            "width": "{tabs.tablist.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'tab': {
        'stroke': {
          value: {
            "color": "{tabs.tab.border.color}",
            "width": "{tabs.tab.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'active': {
          'stroke': {
            value: {
              "color": "{tabs.tab.active.border.color}",
              "width": "{tabs.tab.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{tabs.tab.focus.ring.color}",
                "width": "{tabs.tab.focus.ring.width} + {tabs.tab.focus.ring.offset}",
                "style": "{tabs.tab.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{tabs.tab.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      },
      'tabpanel': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{tabs.tabpanel.focus.ring.color}",
                "width": "{tabs.tabpanel.focus.ring.width} + {tabs.tabpanel.focus.ring.offset}",
                "style": "{tabs.tabpanel.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{tabs.tabpanel.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    },
    'active': {
      'bar': {
        'height': {
          value: '1px',
          type: 'sizing'
        },
        'bottom': {
          value: '-1px',
          type: 'spacing'
        },
        'background': {
          value: '{primary.color}',
          type: 'color'
        }
      }
    }
  },
  'toolbar': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'padding': {
      value: '0.75rem',
      type: 'spacing'
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{toolbar.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    }
  },
  'confirmdialog': {
    'icon': {
      'size': {
        value: '2rem',
        type: 'sizing'
      },
      'color': {
        value: '{overlay.modal.color}',
        type: 'color'
      }
    },
    'content': {
      'gap': {
        value: '1rem',
        type: 'spacing'
      }
    }
  },
  'confirmpopup': {
    'background': {
      value: '{overlay.popover.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{overlay.popover.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{overlay.popover.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{confirmpopup.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'color': {
      value: '{overlay.popover.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.popover.shadow}',
      type: 'boxShadow'
    },
    'gutter': {
      value: '10px',
      type: 'spacing'
    },
    'arrow': {
      'offset': {
        value: '1.25rem',
        type: 'spacing'
      }
    },
    'content': {
      'padding': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '1rem',
        type: 'spacing'
      }
    },
    'icon': {
      'size': {
        value: '1.5rem',
        type: 'sizing'
      },
      'color': {
        value: '{overlay.popover.color}',
        type: 'color'
      }
    },
    'footer': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'padding': {
        value: '0 {overlay.popover.padding} {overlay.popover.padding} {overlay.popover.padding}',
        type: 'spacing'
      }
    }
  },
  'dialog': {
    'background': {
      value: '{overlay.modal.background}',
      type: 'color'
    },
    'color': {
      value: '{overlay.modal.color}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{overlay.modal.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{overlay.modal.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{dialog.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'shadow': {
      value: '{overlay.modal.shadow}',
      type: 'boxShadow'
    },
    'header': {
      'padding': {
        value: '{overlay.modal.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'title': {
      'font': {
        'size': {
          value: '1.25rem',
          type: 'fontSizes'
        },
        'weight': {
          value: '600',
          type: 'fontWeights'
        }
      }
    },
    'content': {
      'padding': {
        value: '0 {overlay.modal.padding} {overlay.modal.padding} {overlay.modal.padding}',
        type: 'spacing'
      }
    },
    'footer': {
      'padding': {
        value: '0 {overlay.modal.padding} {overlay.modal.padding} {overlay.modal.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    }
  },
  'tooltip': {
    'max': {
      'width': {
        value: '12.5rem',
        type: 'sizing'
      }
    },
    'gutter': {
      value: '0.25rem',
      type: 'spacing'
    },
    'shadow': {
      value: '{overlay.popover.shadow}',
      type: 'boxShadow'
    },
    'padding': {
      value: '0.5rem 0.75rem',
      type: 'spacing'
    },
    'border': {
      'radius': {
        value: '{overlay.popover.border.radius}',
        type: 'borderRadius'
      }
    }
  },
  'contextmenu': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.navigation.shadow}',
      type: 'boxShadow'
    },
    'list': {
      'padding': {
        value: '{navigation.list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{navigation.list.gap}',
        type: 'spacing'
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'active': {
        'background': {
          value: '{navigation.item.active.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.active.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{navigation.item.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '{navigation.item.gap}',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.item.icon.active.color}',
            type: 'color'
          }
        }
      }
    },
    'submenu': {
      'icon': {
        'size': {
          value: '{navigation.submenu.icon.size}',
          type: 'sizing'
        },
        'color': {
          value: '{navigation.submenu.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.submenu.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.submenu.icon.active.color}',
            type: 'color'
          }
        }
      },
      'mobile': {
        'indent': {
          value: '1rem',
          type: 'spacing'
        }
      }
    },
    'separator': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{contextmenu.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'separator': {
        'stroke': {
          value: {
            "color": "{contextmenu.separator.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'dock': {
    'background': {
      value: 'rgba(255, 255, 255, 0.1)',
      type: 'color'
    },
    'border': {
      'color': {
        value: 'rgba(255, 255, 255, 0.2)',
        type: 'color'
      },
      'radius': {
        value: '{border.radius.xl}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{dock.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'item': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{dock.item.focus.ring.color}",
                "width": "{dock.item.focus.ring.width} + {dock.item.focus.ring.offset}",
                "style": "{dock.item.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{dock.item.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    },
    'padding': {
      value: '0.5rem',
      type: 'spacing'
    },
    'item': {
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.5rem',
        type: 'spacing'
      },
      'size': {
        value: '3rem',
        type: 'sizing'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'megamenu': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'vertical': {
      'orientation': {
        'padding': {
          value: '{navigation.list.padding}',
          type: 'spacing'
        },
        'gap': {
          value: '{navigation.list.gap}',
          type: 'spacing'
        }
      }
    },
    'horizontal': {
      'orientation': {
        'padding': {
          value: '0.5rem 0.75rem',
          type: 'spacing'
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      }
    },
    'base': {
      'item': {
        'border': {
          'radius': {
            value: '{content.border.radius}',
            type: 'borderRadius'
          }
        },
        'padding': {
          value: '{navigation.item.padding}',
          type: 'spacing'
        }
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'active': {
        'background': {
          value: '{navigation.item.active.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.active.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{navigation.item.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '{navigation.item.gap}',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.item.icon.active.color}',
            type: 'color'
          }
        }
      }
    },
    'overlay': {
      'padding': {
        value: '0',
        type: 'spacing'
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'shadow': {
        value: '{overlay.navigation.shadow}',
        type: 'boxShadow'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'figma': {
      'overlay': {
        'stroke': {
          value: {
            "color": "{megamenu.overlay.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'separator': {
        'stroke': {
          value: {
            "color": "{megamenu.separator.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'mobile': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{megamenu.mobile.button.focus.ring.color}",
                  "width": "{megamenu.mobile.button.focus.ring.width} + {megamenu.mobile.button.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{megamenu.mobile.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      },
      'stroke': {
        value: {
          "color": "{megamenu.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'submenu': {
      'label': {
        'padding': {
          value: '{navigation.submenu.label.padding}',
          type: 'spacing'
        },
        'font': {
          'weight': {
            value: '{navigation.submenu.label.font.weight}',
            type: 'fontWeights'
          }
        },
        'background': {
          value: '{navigation.submenu.label.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.submenu.label.color}',
          type: 'color'
        }
      },
      'icon': {
        'size': {
          value: '{navigation.submenu.icon.size}',
          type: 'sizing'
        },
        'color': {
          value: '{navigation.submenu.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.submenu.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.submenu.icon.active.color}',
            type: 'color'
          }
        }
      },
      'padding': {
        value: '{navigation.list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{navigation.list.gap}',
        type: 'spacing'
      }
    },
    'separator': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    },
    'mobile': {
      'button': {
        'border': {
          'radius': {
            value: '{megamenu.mobile.button.size} / 2',
            type: 'borderRadius'
          }
        },
        'size': {
          value: '1.75rem',
          type: 'sizing'
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          },
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'menu': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{menu.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'separator': {
        'stroke': {
          value: {
            "color": "{menu.separator.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.navigation.shadow}',
      type: 'boxShadow'
    },
    'list': {
      'padding': {
        value: '{navigation.list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{navigation.list.gap}',
        type: 'spacing'
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{navigation.item.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '{navigation.item.gap}',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        }
      }
    },
    'submenu': {
      'label': {
        'padding': {
          value: '{navigation.submenu.label.padding}',
          type: 'spacing'
        },
        'font': {
          'weight': {
            value: '{navigation.submenu.label.font.weight}',
            type: 'fontWeights'
          }
        },
        'background': {
          value: '{navigation.submenu.label.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.submenu.label.color}',
          type: 'color'
        }
      }
    },
    'separator': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    }
  },
  'menubar': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{menubar.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'submenu': {
        'stroke': {
          value: {
            "color": "{menubar.submenu.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'separator': {
        'stroke': {
          value: {
            "color": "{menubar.separator.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'mobile': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{menubar.mobile.button.focus.ring.color}",
                  "width": "{menubar.mobile.button.focus.ring.width} + {menubar.mobile.button.focus.ring.offset}",
                  "style": "{menubar.mobile.button.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{menubar.mobile.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'padding': {
      value: '0.5rem 0.75rem',
      type: 'spacing'
    },
    'base': {
      'item': {
        'border': {
          'radius': {
            value: '{content.border.radius}',
            type: 'borderRadius'
          }
        },
        'padding': {
          value: '{navigation.item.padding}',
          type: 'spacing'
        }
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'active': {
        'background': {
          value: '{navigation.item.active.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.active.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{navigation.item.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '{navigation.item.gap}',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.item.icon.active.color}',
            type: 'color'
          }
        }
      }
    },
    'submenu': {
      'padding': {
        value: '{navigation.list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{navigation.list.gap}',
        type: 'spacing'
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'shadow': {
        value: '{overlay.navigation.shadow}',
        type: 'boxShadow'
      },
      'mobile': {
        'indent': {
          value: '1rem',
          type: 'spacing'
        }
      },
      'icon': {
        'size': {
          value: '{navigation.submenu.icon.size}',
          type: 'sizing'
        },
        'color': {
          value: '{navigation.submenu.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.submenu.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.submenu.icon.active.color}',
            type: 'color'
          }
        }
      }
    },
    'separator': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    },
    'mobile': {
      'button': {
        'border': {
          'radius': {
            value: '{menubar.mobile.button.size} / 2',
            type: 'borderRadius'
          }
        },
        'size': {
          value: '1.75rem',
          type: 'sizing'
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          },
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'panelmenu': {
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    },
    'panel': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.25rem 0.25rem',
        type: 'spacing'
      },
      'first': {
        'border': {
          'width': {
            value: '1px',
            type: 'borderWidth'
          }
        },
        'top': {
          'border': {
            'radius': {
              value: '{content.border.radius}',
              type: 'borderRadius'
            }
          }
        }
      },
      'last': {
        'border': {
          'width': {
            value: '1px',
            type: 'borderWidth'
          }
        },
        'bottom': {
          'border': {
            'radius': {
              value: '{content.border.radius}',
              type: 'borderRadius'
            }
          }
        }
      }
    },
    'figma': {
      'panel': {
        'stroke': {
          value: {
            "color": "{panelmenu.panel.border.color}",
            "width": "{panelmenu.panel.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        }
      }
    },
    'submenu': {
      'indent': {
        value: '1rem',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.submenu.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.submenu.icon.focus.color}',
            type: 'color'
          }
        }
      }
    }
  },
  'tieredmenu': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{tieredmenu.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'separator': {
        'stroke': {
          value: {
            "color": "{tieredmenu.separator.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.navigation.shadow}',
      type: 'boxShadow'
    },
    'list': {
      'padding': {
        value: '{navigation.list.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '{navigation.list.gap}',
        type: 'spacing'
      }
    },
    'item': {
      'focus': {
        'background': {
          value: '{navigation.item.focus.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.focus.color}',
          type: 'color'
        }
      },
      'active': {
        'background': {
          value: '{navigation.item.active.background}',
          type: 'color'
        },
        'color': {
          value: '{navigation.item.active.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{navigation.item.color}',
        type: 'color'
      },
      'padding': {
        value: '{navigation.item.padding}',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{navigation.item.border.radius}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '{navigation.item.gap}',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{navigation.item.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.item.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.item.icon.active.color}',
            type: 'color'
          }
        }
      }
    },
    'submenu': {
      'icon': {
        'size': {
          value: '{navigation.submenu.icon.size}',
          type: 'sizing'
        },
        'color': {
          value: '{navigation.submenu.icon.color}',
          type: 'color'
        },
        'focus': {
          'color': {
            value: '{navigation.submenu.icon.focus.color}',
            type: 'color'
          }
        },
        'active': {
          'color': {
            value: '{navigation.submenu.icon.active.color}',
            type: 'color'
          }
        }
      },
      'mobile': {
        'indent': {
          value: '1rem',
          type: 'spacing'
        }
      }
    },
    'separator': {
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      }
    }
  },
  'orderlist': {
    'gap': {
      value: '1.125rem',
      type: 'spacing'
    },
    'controls': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    }
  },
  'organizationchart': {
    'gutter': {
      value: '0.75rem',
      type: 'spacing'
    },
    'node': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{highlight.background}',
          type: 'color'
        },
        'color': {
          value: '{highlight.color}',
          type: 'color'
        }
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      },
      'toggleable': {
        'padding': {
          value: '0.75rem 1rem 1.25rem 1rem',
          type: 'spacing'
        }
      },
      'toggle': {
        'button': {
          'background': {
            value: '{content.background}',
            type: 'color'
          },
          'hover': {
            'background': {
              value: '{content.hover.background}',
              type: 'color'
            },
            'color': {
              value: '{text.color}',
              type: 'color'
            }
          },
          'border': {
            'color': {
              value: '{content.border.color}',
              type: 'color'
            },
            'radius': {
              value: '{organizationchart.node.toggle.button.size} / 2',
              type: 'borderRadius'
            }
          },
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          },
          'size': {
            value: '1.5rem',
            type: 'sizing'
          },
          'focus': {
            'ring': {
              'width': {
                value: '{focus.ring.width}',
                type: 'borderWidth'
              },
              'offset': {
                value: '{focus.ring.offset}',
                type: 'borderWidth'
              },
              'color': {
                value: '{focus.ring.color}',
                type: 'color'
              },
              'style': {
                value: '{focus.ring.style}',
                type: 'other'
              },
              'shadow': {
                value: '{focus.ring.shadow}',
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'figma': {
      'node': {
        'stroke': {
          value: {
            "color": "{organizationchart.node.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        },
        'toggle': {
          'button': {
            'stroke': {
              value: {
                "color": "{organizationchart.node.toggle.button.border.color}",
                "width": "1",
                "style": "solid"
              },
              type: 'border'
            },
            'focus': {
              'ring': {
                'stroke': {
                  value: {
                    "color": "{organizationchart.node.toggle.button.focus.ring.color}",
                    "width": "{organizationchart.node.toggle.button.focus.ring.width} + {organizationchart.node.toggle.button.focus.ring.offset}",
                    "style": "{organizationchart.node.toggle.button.focus.ring.style}"
                  },
                  type: 'border'
                },
                'offset': {
                  'stroke': {
                    value: {
                      "color": "{app.card.background}",
                      "width": "{organizationchart.node.toggle.button.focus.ring.offset}",
                      "style": "solid"
                    },
                    type: 'border'
                  }
                }
              }
            }
          }
        }
      }
    },
    'connector': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'height': {
        value: '24px',
        type: 'sizing'
      }
    }
  },
  'paginator': {
    'padding': {
      value: '0.5rem 1rem',
      type: 'spacing'
    },
    'gap': {
      value: '0.25rem',
      type: 'spacing'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'nav': {
      'button': {
        'background': {
          value: 'transparent',
          type: 'color'
        },
        'hover': {
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          },
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          }
        },
        'selected': {
          'background': {
            value: '{highlight.background}',
            type: 'color'
          },
          'color': {
            value: '{highlight.color}',
            type: 'color'
          }
        },
        'width': {
          value: '2.5rem',
          type: 'sizing'
        },
        'height': {
          value: '2.5rem',
          type: 'sizing'
        },
        'border': {
          'radius': {
            value: '{paginator.nav.button.width} / 2',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        }
      }
    },
    'figma': {
      'nav': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{paginator.nav.button.focus.ring.color}",
                  "width": "{paginator.nav.button.focus.ring.width} + {paginator.nav.button.focus.ring.offset}",
                  "style": "{paginator.nav.button.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{paginator.nav.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      }
    },
    'current': {
      'page': {
        'report': {
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          }
        }
      }
    },
    'jump': {
      'to': {
        'page': {
          'input': {
            'max': {
              'width': {
                value: '2.5rem',
                type: 'sizing'
              }
            }
          }
        }
      }
    }
  },
  'picklist': {
    'gap': {
      value: '1.125rem',
      type: 'spacing'
    },
    'controls': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    }
  },
  'datatable': {
    'header': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{datatable.border.color}',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      },
      'cell': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'hover': {
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          },
          'color': {
            value: '{content.hover.color}',
            type: 'color'
          }
        },
        'selected': {
          'background': {
            value: '{highlight.background}',
            type: 'color'
          },
          'color': {
            value: '{highlight.color}',
            type: 'color'
          }
        },
        'border': {
          'color': {
            value: '{datatable.border.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '-1px',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'figma': {
      'header': {
        'stroke': {
          value: {
            "color": "{datatable.header.border.color}",
            "width": "{datatable.header.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'cell': {
          'stroke': {
            value: {
              "color": "{datatable.header.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'spacing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'spacing'
            }
          }
        },
        'padding': {
          'sm': {
            value: '0.375rem 0.5rem',
            type: 'spacing'
          },
          'lg': {
            value: '0.9375rem 1.25rem',
            type: 'spacing'
          }
        }
      },
      'body': {
        'cell': {
          'stroke': {
            value: {
              "color": "{datatable.body.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'spacing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'spacing'
            }
          }
        }
      },
      'footer': {
        'cell': {
          'stroke': {
            value: {
              "color": "{datatable.footer.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'spacing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'spacing'
            }
          }
        },
        'stroke': {
          value: {
            "color": "{datatable.footer.border.color}",
            "width": "{datatable.footer.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'padding': {
          'sm': {
            value: '0.375rem 0.5rem',
            type: 'spacing'
          },
          'lg': {
            value: '0.9375rem 1.25rem',
            type: 'spacing'
          }
        }
      },
      'row': {
        'toggle': {
          'button': {
            'focus': {
              'ring': {
                'stroke': {
                  value: {
                    "color": "{datatable.row.toggle.button.focus.ring.color}",
                    "width": "{datatable.row.toggle.button.focus.ring.width} + {datatable.row.toggle.button.focus.ring.offset}",
                    "style": "{datatable.row.toggle.button.focus.ring.style}"
                  },
                  type: 'border'
                },
                'offset': {
                  'stroke': {
                    value: {
                      "color": "{app.card.background}",
                      "width": "{datatable.row.toggle.button.focus.ring.offset}",
                      "style": "solid"
                    },
                    type: 'border'
                  }
                }
              }
            }
          }
        }
      },
      'filter': {
        'overlay': {
          'select': {
            'stroke': {
              value: {
                "color": "{datatable.filter.overlay.select.border.color}",
                "width": "1",
                "style": "solid"
              },
              type: 'border'
            }
          },
          'popover': {
            'stroke': {
              value: {
                "color": "{datatable.filter.overlay.popover.border.color}",
                "width": "1",
                "style": "solid"
              },
              type: 'border'
            }
          }
        },
        'constraint': {
          'separator': {
            'stroke': {
              value: {
                "color": "{datatable.filter.constraint.separator.border.color}",
                "width": "1",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      },
      'paginator': {
        'top': {
          'stroke': {
            value: {
              "color": "{datatable.paginator.top.border.color}",
              "width": "{datatable.paginator.top.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'bottom': {
          'stroke': {
            value: {
              "color": "{datatable.paginator.bottom.border.color}",
              "width": "{datatable.paginator.bottom.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        }
      }
    },
    'column': {
      'title': {
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      },
      'footer': {
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      },
      'resizer': {
        'width': {
          value: '0.5rem',
          type: 'sizing'
        }
      }
    },
    'row': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{highlight.background}',
          type: 'color'
        },
        'color': {
          value: '{highlight.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '-1px',
            type: 'borderWidth'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          }
        }
      },
      'toggle': {
        'button': {
          'hover': {
            'background': {
              value: '{content.hover.background}',
              type: 'color'
            },
            'color': {
              value: '{text.color}',
              type: 'color'
            }
          },
          'selected': {
            'hover': {
              'background': {
                value: '{content.background}',
                type: 'color'
              },
              'color': {
                value: '{primary.color}',
                type: 'color'
              }
            }
          },
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          },
          'size': {
            value: '1.75rem',
            type: 'sizing'
          },
          'border': {
            'radius': {
              value: '{datatable.row.toggle.button.size} / 2',
              type: 'borderRadius'
            }
          },
          'focus': {
            'ring': {
              'width': {
                value: '{focus.ring.width}',
                type: 'borderWidth'
              },
              'style': {
                value: '{focus.ring.style}',
                type: 'other'
              },
              'offset': {
                value: '{focus.ring.offset}',
                type: 'borderWidth'
              },
              'color': {
                value: '{focus.ring.color}',
                type: 'color'
              },
              'shadow': {
                value: '{focus.ring.shadow}',
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'body': {
      'cell': {
        'border': {
          'color': {
            value: '{datatable.border.color}',
            type: 'color'
          }
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        }
      }
    },
    'footer': {
      'cell': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'border': {
          'color': {
            value: '{datatable.border.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        }
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{datatable.border.color}',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      }
    },
    'drop': {
      'point': {
        'color': {
          value: '{primary.color}',
          type: 'color'
        }
      }
    },
    'resize': {
      'indicator': {
        'width': {
          value: '1px',
          type: 'sizing'
        },
        'color': {
          value: '{primary.color}',
          type: 'color'
        }
      }
    },
    'sort': {
      'icon': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          }
        },
        'size': {
          value: '0.875rem',
          type: 'sizing'
        }
      }
    },
    'loading': {
      'icon': {
        'size': {
          value: '2rem',
          type: 'sizing'
        }
      }
    },
    'filter': {
      'inline': {
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      },
      'overlay': {
        'select': {
          'background': {
            value: '{overlay.select.background}',
            type: 'color'
          },
          'border': {
            'color': {
              value: '{overlay.select.border.color}',
              type: 'color'
            },
            'radius': {
              value: '{overlay.select.border.radius}',
              type: 'borderRadius'
            }
          },
          'color': {
            value: '{overlay.select.color}',
            type: 'color'
          },
          'shadow': {
            value: '{overlay.select.shadow}',
            type: 'boxShadow'
          }
        },
        'popover': {
          'background': {
            value: '{overlay.popover.background}',
            type: 'color'
          },
          'border': {
            'color': {
              value: '{overlay.popover.border.color}',
              type: 'color'
            },
            'radius': {
              value: '{overlay.popover.border.radius}',
              type: 'borderRadius'
            }
          },
          'color': {
            value: '{overlay.popover.color}',
            type: 'color'
          },
          'shadow': {
            value: '{overlay.popover.shadow}',
            type: 'boxShadow'
          },
          'padding': {
            value: '{overlay.popover.padding}',
            type: 'spacing'
          },
          'gap': {
            value: '0.5rem',
            type: 'spacing'
          }
        }
      },
      'rule': {
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        }
      },
      'constraint': {
        'list': {
          'padding': {
            value: '{list.padding}',
            type: 'spacing'
          },
          'gap': {
            value: '{list.gap}',
            type: 'spacing'
          }
        },
        'focus': {
          'background': {
            value: '{list.option.focus.background}',
            type: 'color'
          },
          'color': {
            value: '{list.option.focus.color}',
            type: 'color'
          }
        },
        'selected': {
          'background': {
            value: '{list.option.selected.background}',
            type: 'color'
          },
          'focus': {
            'background': {
              value: '{list.option.selected.focus.background}',
              type: 'color'
            },
            'color': {
              value: '{list.option.selected.focus.color}',
              type: 'color'
            }
          },
          'color': {
            value: '{list.option.selected.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{list.option.color}',
          type: 'color'
        },
        'separator': {
          'border': {
            'color': {
              value: '{content.border.color}',
              type: 'color'
            }
          }
        },
        'padding': {
          value: '{list.option.padding}',
          type: 'spacing'
        },
        'border': {
          'radius': {
            value: '{list.option.border.radius}',
            type: 'borderRadius'
          }
        }
      }
    },
    'paginator': {
      'top': {
        'border': {
          'color': {
            value: '{datatable.border.color}',
            type: 'color'
          },
          'width': {
            value: '1',
            type: 'borderWidth'
          }
        }
      },
      'bottom': {
        'border': {
          'color': {
            value: '{datatable.border.color}',
            type: 'color'
          },
          'width': {
            value: '1',
            type: 'borderWidth'
          }
        }
      }
    }
  },
  'timeline': {
    'event': {
      'min': {
        'height': {
          value: '5rem',
          type: 'sizing'
        }
      },
      'marker': {
        'size': {
          value: '1.125rem',
          type: 'sizing'
        },
        'border': {
          'radius': {
            value: '{timeline.event.marker.size} / 2',
            type: 'borderRadius'
          },
          'width': {
            value: '2px',
            type: 'borderWidth'
          },
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        },
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'content': {
          'border': {
            'radius': {
              value: '{timeline.event.marker.content.size} / 2',
              type: 'borderRadius'
            }
          },
          'size': {
            value: '0.375rem',
            type: 'sizing'
          },
          'background': {
            value: '{primary.color}',
            type: 'color'
          },
          'inset': {
            'shadow': {
              value: [{
                "x": "0",
                "y": "0.5",
                "blur": "0",
                "spread": "0",
                "color": "rgba(0, 0, 0, 0.06)",
                "type": "dropShadow"
              }, {
                "x": "0",
                "y": "1",
                "blur": "1",
                "spread": "0",
                "color": "rgba(0, 0, 0, 0.12)",
                "type": "dropShadow"
              }],
              type: 'boxShadow'
            }
          }
        }
      },
      'connector': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        },
        'size': {
          value: '2px',
          type: 'sizing'
        }
      }
    },
    'horizontal': {
      'event': {
        'content': {
          'padding': {
            value: '1rem 0',
            type: 'spacing'
          }
        }
      }
    },
    'vertical': {
      'event': {
        'content': {
          'padding': {
            value: '0 1rem',
            type: 'spacing'
          }
        }
      }
    },
    'figma': {
      'event': {
        'marker': {
          'stroke': {
            value: {
              "color": "{timeline.event.marker.border.color}",
              "width": "{timeline.event.marker.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        }
      }
    }
  },
  'tree': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'padding': {
      value: '1rem',
      type: 'spacing'
    },
    'gap': {
      value: '2px',
      type: 'spacing'
    },
    'indent': {
      value: '1rem',
      type: 'spacing'
    },
    'node': {
      'padding': {
        value: '0.25rem 0.5rem',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{text.hover.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{highlight.background}',
          type: 'color'
        },
        'color': {
          value: '{highlight.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{text.color}',
        type: 'color'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '-1px',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      },
      'gap': {
        value: '0.25rem',
        type: 'spacing'
      },
      'icon': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          }
        },
        'selected': {
          'color': {
            value: '{highlight.color}',
            type: 'color'
          }
        }
      },
      'toggle': {
        'button': {
          'border': {
            'radius': {
              value: '{tree.node.toggle.button.size} / 2',
              type: 'borderRadius'
            }
          },
          'size': {
            value: '1.75rem',
            type: 'sizing'
          },
          'hover': {
            'background': {
              value: '{content.hover.background}',
              type: 'color'
            },
            'color': {
              value: '{text.hover.muted.color}',
              type: 'color'
            }
          },
          'selected': {
            'hover': {
              'background': {
                value: '{content.background}',
                type: 'color'
              },
              'color': {
                value: '{primary.color}',
                type: 'color'
              }
            }
          },
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          },
          'focus': {
            'ring': {
              'width': {
                value: '{focus.ring.width}',
                type: 'borderWidth'
              },
              'offset': {
                value: '{focus.ring.offset}',
                type: 'borderWidth'
              },
              'style': {
                value: '{focus.ring.style}',
                type: 'other'
              },
              'color': {
                value: '{focus.ring.color}',
                type: 'color'
              },
              'shadow': {
                value: '{focus.ring.shadow}',
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'figma': {
      'node': {
        'toggle': {
          'button': {
            'focus': {
              'ring': {
                'stroke': {
                  value: {
                    "color": "{tree.node.toggle.button.focus.ring.color}",
                    "width": "{tree.node.toggle.button.focus.ring.width} + {tree.node.toggle.button.focus.ring.offset}",
                    "style": "{tree.node.toggle.button.focus.ring.style}"
                  },
                  type: 'border'
                },
                'offset': {
                  'stroke': {
                    value: {
                      "color": "{app.card.background}",
                      "width": "{tree.node.toggle.button.focus.ring.offset}",
                      "style": "solid"
                    },
                    type: 'border'
                  }
                }
              }
            }
          }
        }
      }
    },
    'loading': {
      'icon': {
        'size': {
          value: '2rem',
          type: 'sizing'
        }
      }
    },
    'filter': {
      'margin': {
        value: '0.5rem',
        type: 'spacing'
      }
    }
  },
  'virtualscroller': {
    'loader': {
      'mask': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        }
      },
      'icon': {
        'size': {
          value: '2rem',
          type: 'sizing'
        }
      }
    }
  },
  'carousel': {
    'content': {
      'gap': {
        value: '0.25rem',
        type: 'spacing'
      }
    },
    'indicator': {
      'list': {
        'padding': {
          value: '1rem',
          type: 'spacing'
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      },
      'width': {
        value: '2rem',
        type: 'sizing'
      },
      'height': {
        value: '0.5rem',
        type: 'sizing'
      },
      'border': {
        'radius': {
          value: '{content.border.radius}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'figma': {
      'indicator': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{carousel.indicator.focus.ring.color}",
                "width": "{carousel.indicator.focus.ring.width} + {carousel.indicator.focus.ring.offset}",
                "style": "{carousel.indicator.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{carousel.indicator.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'image': {
    'preview': {
      'icon': {
        'size': {
          value: '1.5rem',
          type: 'sizing'
        }
      },
      'mask': {
        'background': {
          value: '{mask.background}',
          type: 'color'
        },
        'color': {
          value: '{mask.color}',
          type: 'color'
        }
      }
    },
    'toolbar': {
      'background': {
        value: 'rgba(255, 255, 255, 0.1)',
        type: 'color'
      },
      'border': {
        'color': {
          value: 'rgba(255, 255, 255, 0.2)',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        },
        'radius': {
          value: '30px',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.5rem',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'position': {
        'right': {
          value: '1rem',
          type: 'spacing'
        },
        'top': {
          value: '1rem',
          type: 'spacing'
        }
      }
    },
    'action': {
      'hover': {
        'background': {
          value: 'rgba(255, 255, 255, 0.1)',
          type: 'color'
        },
        'color': {
          value: '{surface.0}',
          type: 'color'
        }
      },
      'color': {
        value: '{surface.50}',
        type: 'color'
      },
      'size': {
        value: '3rem',
        type: 'sizing'
      },
      'icon': {
        'size': {
          value: '1.5rem',
          type: 'sizing'
        }
      },
      'border': {
        'radius': {
          value: '{image.action.size} / 2',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'figma': {
      'action': {
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{image.action.focus.ring.color}",
                "width": "{image.action.focus.ring.width} + {image.action.focus.ring.offset}",
                "style": "{image.action.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "{app.card.background}",
                  "width": "{image.action.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      },
      'toolbar': {
        'stroke': {
          value: {
            "color": "{image.toolbar.border.color}",
            "width": "{image.toolbar.border.width}",
            "style": "solid"
          },
          type: 'border'
        }
      }
    }
  },
  'treetable': {
    'header': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{treetable.border.color}',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      },
      'cell': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'hover': {
          'background': {
            value: '{content.hover.background}',
            type: 'color'
          },
          'color': {
            value: '{content.hover.color}',
            type: 'color'
          }
        },
        'selected': {
          'background': {
            value: '{highlight.background}',
            type: 'color'
          },
          'color': {
            value: '{highlight.color}',
            type: 'color'
          }
        },
        'border': {
          'color': {
            value: '{treetable.border.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '-1px',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'figma': {
      'header': {
        'stroke': {
          value: {
            "color": "{treetable.header.border.color}",
            "width": "{treetable.header.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'cell': {
          'stroke': {
            value: {
              "color": "{treetable.header.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'sizing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'sizing'
            }
          }
        },
        'padding': {
          'sm': {
            value: '0.375rem 0.5rem',
            type: 'sizing'
          },
          'lg': {
            value: '0.9375rem 1.25rem',
            type: 'sizing'
          }
        }
      },
      'body': {
        'cell': {
          'stroke': {
            value: {
              "color": "{treetable.body.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'sizing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'sizing'
            }
          }
        }
      },
      'footer': {
        'cell': {
          'stroke': {
            value: {
              "color": "{treetable.footer.cell.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          },
          'padding': {
            'sm': {
              value: '0.375rem 0.5rem',
              type: 'sizing'
            },
            'lg': {
              value: '0.9375rem 1.25rem',
              type: 'sizing'
            }
          }
        },
        'stroke': {
          value: {
            "color": "{treetable.footer.border.color}",
            "width": "{treetable.footer.border.width}",
            "style": "solid"
          },
          type: 'border'
        },
        'padding': {
          'sm': {
            value: '0.375rem 0.5rem',
            type: 'sizing'
          },
          'lg': {
            value: '0.9375rem 1.25rem',
            type: 'sizing'
          }
        }
      },
      'node': {
        'toggle': {
          'button': {
            'focus': {
              'ring': {
                'stroke': {
                  value: {
                    "color": "{treetable.node.toggle.button.focus.ring.color}",
                    "width": "{treetable.node.toggle.button.focus.ring.width} + {treetable.node.toggle.button.focus.ring.offset}",
                    "style": "{treetable.node.toggle.button.focus.ring.style}"
                  },
                  type: 'border'
                },
                'offset': {
                  'stroke': {
                    value: {
                      "color": "{app.card.background}",
                      "width": "{treetable.node.toggle.button.focus.ring.offset}",
                      "style": "solid"
                    },
                    type: 'border'
                  }
                }
              }
            }
          }
        }
      },
      'paginator': {
        'top': {
          'stroke': {
            value: {
              "color": "{treetable.paginator.top.border.color}",
              "width": "{treetable.paginator.top.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        },
        'bottom': {
          'stroke': {
            value: {
              "color": "{treetable.paginator.bottom.border.color}",
              "width": "{treetable.paginator.bottom.border.width}",
              "style": "solid"
            },
            type: 'border'
          }
        }
      }
    },
    'column': {
      'title': {
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      },
      'footer': {
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      },
      'resizer': {
        'width': {
          value: '0.5rem',
          type: 'sizing'
        }
      }
    },
    'row': {
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      },
      'selected': {
        'background': {
          value: '{highlight.background}',
          type: 'color'
        },
        'color': {
          value: '{highlight.color}',
          type: 'color'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '-1px',
            type: 'borderWidth'
          },
          'color': {
            value: '{focus.ring.color}',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'body': {
      'cell': {
        'border': {
          'color': {
            value: '{treetable.border.color}',
            type: 'color'
          }
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      }
    },
    'footer': {
      'cell': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'border': {
          'color': {
            value: '{treetable.border.color}',
            type: 'color'
          }
        },
        'color': {
          value: '{content.color}',
          type: 'color'
        },
        'padding': {
          value: '0.75rem 1rem',
          type: 'spacing'
        }
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'border': {
        'color': {
          value: '{treetable.border.color}',
          type: 'color'
        },
        'width': {
          value: '1px',
          type: 'borderWidth'
        }
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      },
      'padding': {
        value: '0.75rem 1rem',
        type: 'spacing'
      }
    },
    'resize': {
      'indicator': {
        'width': {
          value: '1px',
          type: 'sizing'
        },
        'color': {
          value: '{primary.color}',
          type: 'color'
        }
      }
    },
    'sort': {
      'icon': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'hover': {
          'color': {
            value: '{text.hover.muted.color}',
            type: 'color'
          }
        },
        'size': {
          value: '0.875rem',
          type: 'sizing'
        }
      }
    },
    'loading': {
      'icon': {
        'size': {
          value: '2rem',
          type: 'sizing'
        }
      }
    },
    'node': {
      'toggle': {
        'button': {
          'hover': {
            'background': {
              value: '{content.hover.background}',
              type: 'color'
            },
            'color': {
              value: '{text.color}',
              type: 'color'
            }
          },
          'selected': {
            'hover': {
              'background': {
                value: '{content.background}',
                type: 'color'
              },
              'color': {
                value: '{primary.color}',
                type: 'color'
              }
            }
          },
          'color': {
            value: '{text.muted.color}',
            type: 'color'
          },
          'size': {
            value: '1.75rem',
            type: 'sizing'
          },
          'border': {
            'radius': {
              value: '{treetable.node.toggle.button.size} / 2',
              type: 'borderRadius'
            }
          },
          'focus': {
            'ring': {
              'width': {
                value: '{focus.ring.width}',
                type: 'borderWidth'
              },
              'offset': {
                value: '{focus.ring.offset}',
                type: 'borderWidth'
              },
              'style': {
                value: '{focus.ring.style}',
                type: 'other'
              },
              'color': {
                value: '{focus.ring.color}',
                type: 'color'
              },
              'shadow': {
                value: '{focus.ring.shadow}',
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'paginator': {
      'top': {
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          },
          'width': {
            value: '1px',
            type: 'borderWidth'
          }
        }
      },
      'bottom': {
        'border': {
          'width': {
            value: '1px',
            type: 'borderWidth'
          },
          'color': {
            value: '{content.border.color}',
            type: 'color'
          }
        }
      }
    }
  },
  'toast': {
    'width': {
      value: '25rem',
      type: 'sizing'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      },
      'width': {
        value: '1px',
        type: 'borderWidth'
      }
    },
    'icon': {
      'size': {
        value: '1.125rem',
        type: 'sizing'
      }
    },
    'content': {
      'padding': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'summary': {
      'font': {
        'weight': {
          value: '500',
          type: 'fontWeights'
        },
        'size': {
          value: '1rem',
          type: 'fontSizes'
        }
      }
    },
    'detail': {
      'font': {
        'weight': {
          value: '500',
          type: 'fontWeights'
        },
        'size': {
          value: '0.875rem',
          type: 'fontSizes'
        }
      }
    },
    'close': {
      'button': {
        'width': {
          value: '1.75rem',
          type: 'sizing'
        },
        'height': {
          value: '1.75rem',
          type: 'sizing'
        },
        'border': {
          'radius': {
            value: '{toast.close.button.width} / 2',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            }
          }
        }
      },
      'icon': {
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      }
    },
    'text': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    }
  },
  'galleria': {
    'border': {
      'width': {
        value: '1px',
        type: 'borderWidth'
      },
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{galleria.border.color}",
          "width": "{galleria.border.width}",
          "style": "solid"
        },
        type: 'border'
      },
      'nav': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{galleria.nav.button.focus.ring.color}",
                  "width": "{galleria.nav.button.focus.ring.width} + {galleria.nav.button.focus.ring.offset}",
                  "style": "{galleria.nav.button.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "rgba(0, 0, 0, 0.01)",
                    "width": "{galleria.nav.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      },
      'thumbnail': {
        'nav': {
          'button': {
            'focus': {
              'ring': {
                'stroke': {
                  value: {
                    "color": "{galleria.thumbnail.nav.button.focus.ring.color}",
                    "width": "{galleria.thumbnail.nav.button.focus.ring.width} + {galleria.thumbnail.nav.button.focus.ring.offset}",
                    "style": "{galleria.thumbnail.nav.button.focus.ring.style}"
                  },
                  type: 'border'
                },
                'offset': {
                  'stroke': {
                    value: {
                      "color": "{app.card.background}",
                      "width": "{galleria.thumbnail.nav.button.focus.ring.offset}",
                      "style": "solid"
                    },
                    type: 'border'
                  }
                }
              }
            }
          }
        }
      },
      'indicator': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{galleria.indicator.button.focus.ring.color}",
                  "width": "{galleria.indicator.button.focus.ring.width} + {galleria.indicator.button.focus.ring.offset}",
                  "style": "{galleria.indicator.button.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{galleria.indicator.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{galleria.close.button.focus.ring.color}",
                  "width": "{galleria.close.button.focus.ring.width} + {galleria.close.button.focus.ring.offset}",
                  "style": "{galleria.close.button.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{galleria.close.button.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        }
      }
    },
    'nav': {
      'button': {
        'background': {
          value: 'rgba(255, 255, 255, 0.1)',
          type: 'color'
        },
        'hover': {
          'background': {
            value: 'rgba(255, 255, 255, 0.2)',
            type: 'color'
          },
          'color': {
            value: '{surface.0}',
            type: 'color'
          }
        },
        'color': {
          value: '{surface.100}',
          type: 'color'
        },
        'size': {
          value: '3rem',
          type: 'sizing'
        },
        'gutter': {
          value: '0.5rem',
          type: 'spacing'
        },
        'prev': {
          'border': {
            'radius': {
              value: '{galleria.nav.button.size} / 2',
              type: 'borderRadius'
            }
          }
        },
        'next': {
          'border': {
            'radius': {
              value: '{galleria.nav.button.size} / 2',
              type: 'borderRadius'
            }
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      },
      'icon': {
        'size': {
          value: '1.5rem',
          type: 'sizing'
        }
      }
    },
    'thumbnails': {
      'content': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'padding': {
          value: '1rem 0.25rem',
          type: 'spacing'
        }
      }
    },
    'thumbnail': {
      'nav': {
        'button': {
          'size': {
            value: '2rem',
            type: 'sizing'
          },
          'border': {
            'radius': {
              value: '{content.border.radius}',
              type: 'borderRadius'
            }
          },
          'gutter': {
            value: '0.5rem',
            type: 'spacing'
          },
          'focus': {
            'ring': {
              'width': {
                value: '{focus.ring.width}',
                type: 'borderWidth'
              },
              'style': {
                value: '{focus.ring.style}',
                type: 'other'
              },
              'color': {
                value: '{focus.ring.color}',
                type: 'color'
              },
              'offset': {
                value: '{focus.ring.offset}',
                type: 'borderWidth'
              },
              'shadow': {
                value: '{focus.ring.shadow}',
                type: 'boxShadow'
              }
            }
          },
          'icon': {
            'size': {
              value: '1rem',
              type: 'sizing'
            }
          }
        }
      }
    },
    'caption': {
      'background': {
        value: 'rgba(0, 0, 0, 0.5)',
        type: 'color'
      },
      'color': {
        value: '{surface.100}',
        type: 'color'
      },
      'padding': {
        value: '1rem',
        type: 'spacing'
      }
    },
    'indicator': {
      'list': {
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        },
        'padding': {
          value: '1rem',
          type: 'spacing'
        }
      },
      'button': {
        'width': {
          value: '1rem',
          type: 'sizing'
        },
        'height': {
          value: '1rem',
          type: 'sizing'
        },
        'active': {
          'background': {
            value: '{primary.color}',
            type: 'color'
          }
        },
        'border': {
          'radius': {
            value: '{galleria.indicator.button.width} / 2',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'inset': {
      'indicator': {
        'list': {
          'background': {
            value: 'rgba(0, 0, 0, 0.5)',
            type: 'color'
          }
        },
        'button': {
          'background': {
            value: 'rgba(255, 255, 255, 0.4)',
            type: 'color'
          },
          'hover': {
            'background': {
              value: 'rgba(255, 255, 255, 0.6)',
              type: 'color'
            }
          },
          'active': {
            'background': {
              value: 'rgba(255, 255, 255, 0.9)',
              type: 'color'
            }
          }
        }
      }
    },
    'close': {
      'button': {
        'size': {
          value: '3rem',
          type: 'sizing'
        },
        'gutter': {
          value: '0.5rem',
          type: 'spacing'
        },
        'background': {
          value: 'rgba(255, 255, 255, 0.1)',
          type: 'color'
        },
        'hover': {
          'background': {
            value: 'rgba(255, 255, 255, 0.2)',
            type: 'color'
          },
          'color': {
            value: '{surface.0}',
            type: 'color'
          }
        },
        'color': {
          value: '{surface.50}',
          type: 'color'
        },
        'border': {
          'radius': {
            value: '{galleria.close.button.size} / 2',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        },
        'icon': {
          'size': {
            value: '1.5rem',
            type: 'sizing'
          }
        }
      }
    }
  },
  'popover': {
    'background': {
      value: '{overlay.popover.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{overlay.popover.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{overlay.popover.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{overlay.popover.color}',
      type: 'color'
    },
    'shadow': {
      value: '{overlay.popover.shadow}',
      type: 'boxShadow'
    },
    'gutter': {
      value: '10px',
      type: 'spacing'
    },
    'arrow': {
      'offset': {
        value: '1.25rem',
        type: 'spacing'
      }
    },
    'content': {
      'padding': {
        value: '{overlay.popover.padding}',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{popover.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    }
  },
  'fileupload': {
    'background': {
      value: '{content.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{content.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'color': {
      value: '{content.color}',
      type: 'color'
    },
    'header': {
      'background': {
        value: 'transparent',
        type: 'color'
      },
      'color': {
        value: '{text.color}',
        type: 'color'
      },
      'padding': {
        value: '1.125rem',
        type: 'spacing'
      },
      'border': {
        'width': {
          value: '0',
          type: 'borderWidth'
        },
        'radius': {
          value: '0',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'content': {
      'highlight': {
        'border': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        }
      },
      'padding': {
        value: '0 1.125rem 1.125rem 1.125rem',
        type: 'spacing'
      },
      'gap': {
        value: '1rem',
        type: 'spacing'
      }
    },
    'file': {
      'padding': {
        value: '1rem',
        type: 'spacing'
      },
      'gap': {
        value: '1rem',
        type: 'spacing'
      },
      'border': {
        'color': {
          value: '{content.border.color}',
          type: 'color'
        }
      },
      'info': {
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      },
      'list': {
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      }
    },
    'progressbar': {
      'height': {
        value: '0.25rem',
        type: 'sizing'
      }
    },
    'basic': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{fileupload.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      },
      'file': {
        'stroke': {
          value: {
            "color": "{fileupload.file.border.color}",
            "width": "1",
            "style": "solid"
          },
          type: 'border'
        }
      },
      'content': {
        'highlight': {
          'stroke': {
            value: {
              "color": "{fileupload.content.highlight.border.color}",
              "width": "1",
              "style": "solid"
            },
            type: 'border'
          }
        }
      }
    }
  },
  'speeddial': {
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    }
  },
  'splitbutton': {
    'border': {
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'rounded': {
      'border': {
        'radius': {
          value: '2rem',
          type: 'borderRadius'
        }
      }
    },
    'raised': {
      'shadow': {
        value: [{
          "x": "0",
          "y": "3",
          "blur": "1",
          "spread": "-2",
          "color": "rgba(0, 0, 0, 0.2)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "2",
          "blur": "2",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.14)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "1",
          "blur": "5",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0.12)",
          "type": "innerShadow"
        }],
        type: 'boxShadow'
      }
    }
  },
  'stepper': {
    'separator': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      },
      'active': {
        'background': {
          value: '{primary.color}',
          type: 'color'
        }
      },
      'margin': {
        value: '0 0 0 1rem',
        type: 'spacing'
      },
      'size': {
        value: '2px',
        type: 'sizing'
      }
    },
    'step': {
      'padding': {
        value: '0.5rem',
        type: 'spacing'
      },
      'gap': {
        value: '1rem',
        type: 'spacing'
      },
      'header': {
        'padding': {
          value: '0',
          type: 'spacing'
        },
        'border': {
          'radius': {
            value: '{content.border.radius}',
            type: 'borderRadius'
          }
        },
        'focus': {
          'ring': {
            'width': {
              value: '{focus.ring.width}',
              type: 'borderWidth'
            },
            'style': {
              value: '{focus.ring.style}',
              type: 'other'
            },
            'color': {
              value: '{focus.ring.color}',
              type: 'color'
            },
            'offset': {
              value: '{focus.ring.offset}',
              type: 'borderWidth'
            },
            'shadow': {
              value: '{focus.ring.shadow}',
              type: 'boxShadow'
            }
          }
        },
        'gap': {
          value: '0.5rem',
          type: 'spacing'
        }
      },
      'title': {
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'active': {
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        },
        'font': {
          'weight': {
            value: '500',
            type: 'fontWeights'
          }
        }
      },
      'number': {
        'background': {
          value: '{content.background}',
          type: 'color'
        },
        'active': {
          'background': {
            value: '{content.background}',
            type: 'color'
          },
          'border': {
            'color': {
              value: '{content.border.color}',
              type: 'color'
            }
          },
          'color': {
            value: '{primary.color}',
            type: 'color'
          }
        },
        'border': {
          'color': {
            value: '{content.border.color}',
            type: 'color'
          },
          'radius': {
            value: '{stepper.step.number.size} / 2',
            type: 'borderRadius'
          }
        },
        'color': {
          value: '{text.muted.color}',
          type: 'color'
        },
        'size': {
          value: '2rem',
          type: 'sizing'
        },
        'font': {
          'size': {
            value: '1.143rem',
            type: 'fontSizes'
          },
          'weight': {
            value: '500',
            type: 'fontWeights'
          }
        },
        'shadow': {
          value: [{
            "x": "0",
            "y": "0.5",
            "blur": "0",
            "spread": "0",
            "color": "rgba(0, 0, 0, 0.06)",
            "type": "dropShadow"
          }, {
            "x": "0",
            "y": "1",
            "blur": "1",
            "spread": "0",
            "color": "rgba(0, 0, 0, 0.12)",
            "type": "dropShadow"
          }],
          type: 'boxShadow'
        }
      }
    },
    'figma': {
      'step': {
        'header': {
          'focus': {
            'ring': {
              'stroke': {
                value: {
                  "color": "{stepper.step.header.focus.ring.color}",
                  "width": "{stepper.step.header.focus.ring.width} + {stepper.step.header.focus.ring.offset}",
                  "style": "{stepper.step.header.focus.ring.style}"
                },
                type: 'border'
              },
              'offset': {
                'stroke': {
                  value: {
                    "color": "{app.card.background}",
                    "width": "{stepper.step.header.focus.ring.offset}",
                    "style": "solid"
                  },
                  type: 'border'
                }
              }
            }
          }
        },
        'number': {
          'stroke': {
            value: {
              "color": "{stepper.step.number.border.color}",
              "width": "2",
              "style": "solid"
            },
            type: 'border'
          },
          'active': {
            'stroke': {
              value: {
                "color": "{stepper.step.number.active.border.color}",
                "width": "2",
                "style": "solid"
              },
              type: 'border'
            }
          }
        }
      }
    },
    'steppanels': {
      'padding': {
        value: '0.875rem 0.5rem 1.125rem 0.5rem',
        type: 'spacing'
      }
    },
    'steppanel': {
      'padding': {
        value: '0 0 0 1rem',
        type: 'spacing'
      },
      'background': {
        value: '{content.background}',
        type: 'color'
      },
      'color': {
        value: '{content.color}',
        type: 'color'
      }
    }
  },
  'blockui': {
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    }
  },
  'inplace': {
    'padding': {
      value: '{form.field.padding.y} {form.field.padding.x}',
      type: 'spacing'
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    },
    'focus': {
      'ring': {
        'width': {
          value: '{focus.ring.width}',
          type: 'borderWidth'
        },
        'offset': {
          value: '{focus.ring.offset}',
          type: 'borderWidth'
        },
        'style': {
          value: '{focus.ring.style}',
          type: 'other'
        },
        'color': {
          value: '{focus.ring.color}',
          type: 'color'
        },
        'shadow': {
          value: '{focus.ring.shadow}',
          type: 'boxShadow'
        }
      }
    },
    'display': {
      'hover': {
        'background': {
          value: '{content.hover.background}',
          type: 'color'
        },
        'color': {
          value: '{content.hover.color}',
          type: 'color'
        }
      }
    }
  },
  'metergroup': {
    'gap': {
      value: '1rem',
      type: 'spacing'
    },
    'meters': {
      'background': {
        value: '{content.border.color}',
        type: 'color'
      },
      'size': {
        value: '0.5rem',
        type: 'sizing'
      }
    },
    'label': {
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      },
      'marker': {
        'size': {
          value: '0.5rem',
          type: 'sizing'
        }
      },
      'icon': {
        'size': {
          value: '1rem',
          type: 'sizing'
        }
      },
      'list': {
        'vertical': {
          'gap': {
            value: '0.5rem',
            type: 'spacing'
          }
        },
        'horizontal': {
          'gap': {
            value: '1rem',
            type: 'spacing'
          }
        }
      }
    },
    'border': {
      'radius': {
        value: '{content.border.radius}',
        type: 'borderRadius'
      }
    }
  },
  'terminal': {
    'background': {
      value: '{form.field.background}',
      type: 'color'
    },
    'border': {
      'color': {
        value: '{form.field.border.color}',
        type: 'color'
      },
      'radius': {
        value: '{form.field.border.radius}',
        type: 'borderRadius'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{terminal.border.color}",
          "width": "1",
          "style": "solid"
        },
        type: 'border'
      }
    },
    'color': {
      value: '{form.field.color}',
      type: 'color'
    },
    'height': {
      value: '18rem',
      type: 'sizing'
    },
    'prompt': {
      'gap': {
        value: '0.25rem',
        type: 'spacing'
      }
    },
    'command': {
      'response': {
        'margin': {
          value: '2px 0',
          type: 'spacing'
        }
      }
    },
    'padding': {
      value: '{form.field.padding.y} {form.field.padding.x}',
      type: 'spacing'
    }
  },
  'imagecompare': {
    'handle': {
      'size': {
        value: '15px',
        type: 'sizing'
      },
      'hover': {
        'size': {
          value: '30px',
          type: 'sizing'
        },
        'background': {
          value: 'rgba(255, 255, 255, 0.3)',
          type: 'color'
        },
        'border': {
          'color': {
            value: 'transparent',
            type: 'color'
          }
        }
      },
      'background': {
        value: 'rgba(255, 255, 255, 0.3)',
        type: 'color'
      },
      'border': {
        'color': {
          value: 'transparent',
          type: 'color'
        },
        'width': {
          value: '0',
          type: 'borderWidth'
        },
        'radius': {
          value: '{imagecompare.handle.size} / 2',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '{focus.ring.width}',
            type: 'borderWidth'
          },
          'offset': {
            value: '{focus.ring.offset}',
            type: 'borderWidth'
          },
          'style': {
            value: '{focus.ring.style}',
            type: 'other'
          },
          'color': {
            value: 'rgba(255, 255, 255, 0.3)',
            type: 'color'
          },
          'shadow': {
            value: '{focus.ring.shadow}',
            type: 'boxShadow'
          }
        }
      }
    },
    'figma': {
      'handle': {
        'stroke': {
          value: {
            "color": "{imagecompare.handle.border.color}",
            "width": "{imagecompare.handle.border.width}",
            "style": "none"
          },
          type: 'border'
        },
        'hover': {
          'stroke': {
            value: {
              "color": "{imagecompare.handle.hover.border.color}",
              "width": "{imagecompare.handle.border.width}",
              "style": "solid"
            },
            type: 'border'
          },
          'border': {
            'radius': {
              value: '{imagecompare.handle.hover.size} / 2',
              type: 'borderRadius'
            }
          }
        },
        'focus': {
          'ring': {
            'stroke': {
              value: {
                "color": "{imagecompare.handle.focus.ring.color}",
                "width": "{imagecompare.handle.focus.ring.width} + {imagecompare.handle.focus.ring.offset}",
                "style": "{imagecompare.handle.focus.ring.style}"
              },
              type: 'border'
            },
            'offset': {
              'stroke': {
                value: {
                  "color": "rgba(0, 0, 0, 0.3)",
                  "width": "{imagecompare.handle.focus.ring.offset}",
                  "style": "solid"
                },
                type: 'border'
              }
            }
          }
        }
      }
    }
  },
  'overlaybadge': {
    'outline': {
      'color': {
        value: '{content.background}',
        type: 'color'
      },
      'width': {
        value: '2px',
        type: 'borderWidth'
      }
    },
    'figma': {
      'stroke': {
        value: {
          "color": "{overlaybadge.outline.color}",
          "width": "{overlaybadge.outline.width}",
          "style": "solid"
        },
        type: 'border'
      }
    }
  },
  'inputotp': {
    'input': {
      'width': {
        value: '2.5rem',
        type: 'sizing'
      },
      'sm': {
        'width': {
          value: '2rem',
          type: 'sizing'
        }
      },
      'lg': {
        'width': {
          value: '3rem',
          type: 'sizing'
        }
      }
    },
    'gap': {
      value: '0.5rem',
      type: 'spacing'
    }
  }
};