import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonHeaderComponent } from './shared/components/common-header/common-header.component';
import { ContactSupportComponent } from './shared/components/contact-support/contact-support.component';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { MetaService } from './shared/services/meta.service';
import { ProjectService } from './shared/services/project.service';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ConfirmPopupModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      useCookiesForTransactions: true,
      authorizationParams: {
        redirect_uri: `${environment.auth0.redirectUrl}`,
        audience: environment.auth0.audience,
        scope: 'access:api openid email profile',
      },
      httpInterceptor: {
        allowedList: [
          {
            /* The endpoint you would like to prefix the access token with */
            uri: `${environment.apiURL}/*`,
          },
          {
            uri: `${environment.apiV2URL}/*`,
          },
        ],
      },
    }),
    ToastModule,
    RouterModule.forRoot([]),
    ContactSupportComponent,
    NavigationComponent,
    CommonHeaderComponent,
    SkeletonModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    MessageService,
    DialogService,
    ConfirmationService,
    DynamicDialogRef,
    StyleClassModule,
    ProjectService,
    MetaService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
