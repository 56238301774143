"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lfxPreset = void 0;
const component_tokens_1 = require("../tokens/component.tokens");
const primitive_tokens_1 = require("../tokens/primitive.tokens");
const semantic_tokens_1 = require("../tokens/semantic.tokens");
const lfx_component_tokens_1 = require("../tokens/lfx.component.tokens");
exports.lfxPreset = {
  component: {
    ...component_tokens_1.componentTokens,
    ...lfx_component_tokens_1.lfxComponentTokens
  },
  primitive: primitive_tokens_1.primitiveTokens,
  semantic: semantic_tokens_1.semanticTokens
};