"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.semanticTokens = void 0;
exports.semanticTokens = {
  'primary': {
    '50': {
      value: '{blue.50}',
      type: 'color'
    },
    '100': {
      value: '{blue.100}',
      type: 'color'
    },
    '200': {
      value: '{blue.200}',
      type: 'color'
    },
    '300': {
      value: '{blue.300}',
      type: 'color'
    },
    '400': {
      value: '{blue.400}',
      type: 'color'
    },
    '500': {
      value: '{blue.500}',
      type: 'color'
    },
    '600': {
      value: '{blue.600}',
      type: 'color'
    },
    '700': {
      value: '{blue.700}',
      type: 'color'
    },
    '800': {
      value: '{blue.800}',
      type: 'color'
    },
    '900': {
      value: '{blue.900}',
      type: 'color'
    },
    '950': {
      value: '{blue.950}',
      type: 'color'
    }
  },
  'anchor': {
    'gutter': {
      value: '2px',
      type: 'spacing'
    }
  },
  'icon': {
    'size': {
      value: '1rem',
      type: 'sizing'
    }
  },
  'disabled': {
    'opacity': {
      value: '0.6',
      type: 'opacity'
    }
  },
  'form': {
    'field': {
      'padding': {
        'x': {
          value: '0.75rem',
          type: 'spacing'
        },
        'y': {
          value: '0.5rem',
          type: 'spacing'
        }
      },
      'border': {
        'radius': {
          value: '{border.radius.md}',
          type: 'borderRadius'
        }
      },
      'focus': {
        'ring': {
          'width': {
            value: '0',
            type: 'borderWidth'
          },
          'style': {
            value: 'none',
            type: 'other'
          },
          'color': {
            value: 'transparent',
            type: 'color'
          },
          'offset': {
            value: '0',
            type: 'borderWidth'
          },
          'shadow': {
            value: {
              "x": "0",
              "y": "0",
              "blur": "0",
              "spread": "0",
              "color": "rgba(0, 0, 0, 0)",
              "type": "dropShadow"
            },
            type: 'boxShadow'
          }
        }
      },
      'sm': {
        'font': {
          'size': {
            value: '0.875rem',
            type: 'fontSizes'
          }
        },
        'padding': {
          'x': {
            value: '0.625rem',
            type: 'spacing'
          },
          'y': {
            value: '0.375rem',
            type: 'spacing'
          }
        }
      },
      'lg': {
        'font': {
          'size': {
            value: '1.125rem',
            type: 'fontSizes'
          }
        },
        'padding': {
          'x': {
            value: '0.875rem',
            type: 'spacing'
          },
          'y': {
            value: '0.625rem',
            type: 'spacing'
          }
        }
      }
    }
  },
  'content': {
    'border': {
      'radius': {
        value: '{border.radius.md}',
        type: 'borderRadius'
      }
    }
  },
  'focus': {
    'ring': {
      'width': {
        value: '1',
        type: 'borderWidth'
      },
      'color': {
        value: '{primary.color}',
        type: 'color'
      },
      'offset': {
        value: '2',
        type: 'borderWidth'
      },
      'shadow': {
        value: {
          "x": "0",
          "y": "0",
          "blur": "0",
          "spread": "0",
          "color": "rgba(0, 0, 0, 0)",
          "type": "dropShadow"
        },
        type: 'boxShadow'
      },
      'stroke': {
        value: {
          "color": "{focus.ring.color}",
          "width": "{focus.ring.offset} + {focus.ring.width}",
          "style": "{focus.ring.style}"
        },
        type: 'border'
      },
      'style': {
        value: 'solid',
        type: 'other'
      }
    }
  },
  'list': {
    'padding': {
      value: '0.25rem 0.25rem',
      type: 'spacing'
    },
    'gap': {
      value: '2px',
      type: 'spacing'
    },
    'header': {
      'padding': {
        value: '0.5rem 0.75rem 0.25rem 0.75rem',
        type: 'spacing'
      }
    },
    'option': {
      'padding': {
        value: '0.5rem 0.75rem',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      },
      'group': {
        'padding': {
          value: '0.5rem 0.75rem',
          type: 'spacing'
        },
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      }
    }
  },
  'navigation': {
    'list': {
      'padding': {
        value: '0.25rem 0.25rem',
        type: 'spacing'
      },
      'gap': {
        value: '2px',
        type: 'spacing'
      }
    },
    'item': {
      'padding': {
        value: '0.5rem 0.75rem',
        type: 'spacing'
      },
      'border': {
        'radius': {
          value: '{border.radius.sm}',
          type: 'borderRadius'
        }
      },
      'gap': {
        value: '0.5rem',
        type: 'spacing'
      }
    },
    'submenu': {
      'label': {
        'padding': {
          value: '0.5rem 0.75rem',
          type: 'spacing'
        },
        'font': {
          'weight': {
            value: '600',
            type: 'fontWeights'
          }
        }
      },
      'icon': {
        'size': {
          value: '0.875rem',
          type: 'sizing'
        }
      }
    }
  },
  'overlay': {
    'select': {
      'border': {
        'radius': {
          value: '{border.radius.md}',
          type: 'borderRadius'
        }
      },
      'shadow': {
        value: [{
          "x": "0",
          "y": "4",
          "blur": "6",
          "spread": "-1",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "2",
          "blur": "4",
          "spread": "-2",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    },
    'popover': {
      'border': {
        'radius': {
          value: '{border.radius.md}',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '0.75rem',
        type: 'spacing'
      },
      'shadow': {
        value: [{
          "x": "0",
          "y": "4",
          "blur": "6",
          "spread": "-1",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "2",
          "blur": "4",
          "spread": "-2",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    },
    'modal': {
      'border': {
        'radius': {
          value: '{border.radius.xl}',
          type: 'borderRadius'
        }
      },
      'padding': {
        value: '1.25rem',
        type: 'spacing'
      },
      'shadow': {
        value: [{
          "x": "0",
          "y": "20",
          "blur": "25",
          "spread": "-5",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "8",
          "blur": "10",
          "spread": "-6",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    },
    'navigation': {
      'shadow': {
        value: [{
          "x": "0",
          "y": "4",
          "blur": "6",
          "spread": "-1",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }, {
          "x": "0",
          "y": "2",
          "blur": "4",
          "spread": "-2",
          "color": "rgba(0, 0, 0, 0.1)",
          "type": "dropShadow"
        }],
        type: 'boxShadow'
      }
    }
  }
};