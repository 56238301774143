"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.primitiveTokens = void 0;
exports.primitiveTokens = {
  'border': {
    'radius': {
      'none': {
        value: '0',
        type: 'borderRadius'
      },
      'xs': {
        value: '2px',
        type: 'borderRadius'
      },
      'sm': {
        value: '4px',
        type: 'borderRadius'
      },
      'md': {
        value: '6px',
        type: 'borderRadius'
      },
      'lg': {
        value: '8px',
        type: 'borderRadius'
      },
      'xl': {
        value: '12px',
        type: 'borderRadius'
      }
    }
  },
  'white': {
    value: '#FFFFFF',
    type: 'color'
  },
  'amber': {
    '50': {
      value: '#FFFCF8',
      type: 'color'
    },
    '100': {
      value: '#FFF5E8',
      type: 'color'
    },
    '200': {
      value: '#FEEACB',
      type: 'color'
    },
    '300': {
      value: '#FDD594',
      type: 'color'
    },
    '400': {
      value: '#FBB65A',
      type: 'color'
    },
    '500': {
      value: '#F89E22',
      type: 'color'
    },
    '600': {
      value: '#DB851C',
      type: 'color'
    },
    '700': {
      value: '#AD6816',
      type: 'color'
    },
    '800': {
      value: '#834F10',
      type: 'color'
    },
    '900': {
      value: '#4D2F09',
      type: 'color'
    },
    '950': {
      value: '#2E1B04',
      type: 'color'
    }
  },
  'blue': {
    '50': {
      value: '#F8FBFF',
      type: 'color'
    },
    '100': {
      value: '#ECF4FF',
      type: 'color'
    },
    '200': {
      value: '#D9E9FF',
      type: 'color'
    },
    '300': {
      value: '#B8D9FF',
      type: 'color'
    },
    '400': {
      value: '#85C2FF',
      type: 'color'
    },
    '500': {
      value: '#009AFF',
      type: 'color'
    },
    '600': {
      value: '#0082D9',
      type: 'color'
    },
    '700': {
      value: '#0061A3',
      type: 'color'
    },
    '800': {
      value: '#00426F',
      type: 'color'
    },
    '900': {
      value: '#002741',
      type: 'color'
    },
    '950': {
      value: '#001321',
      type: 'color'
    }
  },
  'emerald': {
    '50': {
      value: '#F7FCFA',
      type: 'color'
    },
    '100': {
      value: '#EBFAF4',
      type: 'color'
    },
    '200': {
      value: '#D7F4E9',
      type: 'color'
    },
    '300': {
      value: '#B0EBDA',
      type: 'color'
    },
    '400': {
      value: '#70DEC3',
      type: 'color'
    },
    '500': {
      value: '#10BC8A',
      type: 'color'
    },
    '600': {
      value: '#0EA175',
      type: 'color'
    },
    '700': {
      value: '#0B7B59',
      type: 'color'
    },
    '800': {
      value: '#085440',
      type: 'color'
    },
    '900': {
      value: '#053227',
      type: 'color'
    },
    '950': {
      value: '#021912',
      type: 'color'
    }
  },
  'gray': {
    '50': {
      value: '#F9FAFB',
      type: 'color'
    },
    '100': {
      value: '#EBEDF0',
      type: 'color'
    },
    '200': {
      value: '#DEE1E6',
      type: 'color'
    },
    '300': {
      value: '#C8CCD4',
      type: 'color'
    },
    '400': {
      value: '#9CA3AF',
      type: 'color'
    },
    '500': {
      value: '#6B7280',
      type: 'color'
    },
    '600': {
      value: '#4B5563',
      type: 'color'
    },
    '700': {
      value: '#374151',
      type: 'color'
    },
    '800': {
      value: '#1F2937',
      type: 'color'
    },
    '900': {
      value: '#111827',
      type: 'color'
    },
    '950': {
      value: '#030712',
      type: 'color'
    }
  },
  'red': {
    '50': {
      value: '#FFFAFA',
      type: 'color'
    },
    '100': {
      value: '#FEF0F0',
      type: 'color'
    },
    '200': {
      value: '#FCDADA',
      type: 'color'
    },
    '300': {
      value: '#FAB7B7',
      type: 'color'
    },
    '400': {
      value: '#F68E8E',
      type: 'color'
    },
    '500': {
      value: '#EF4444',
      type: 'color'
    },
    '600': {
      value: '#D13939',
      type: 'color'
    },
    '700': {
      value: '#A82E2E',
      type: 'color'
    },
    '800': {
      value: '#7C2424',
      type: 'color'
    },
    '900': {
      value: '#4B1919',
      type: 'color'
    },
    '950': {
      value: '#2C0707',
      type: 'color'
    }
  },
  'violet': {
    '50': {
      value: '#FDFAFF',
      type: 'color'
    },
    '100': {
      value: '#F9F0FF',
      type: 'color'
    },
    '200': {
      value: '#F1E3FF',
      type: 'color'
    },
    '300': {
      value: '#E2CCFF',
      type: 'color'
    },
    '400': {
      value: '#CCA7FF',
      type: 'color'
    },
    '500': {
      value: '#9B5CF7',
      type: 'color'
    },
    '600': {
      value: '#834AD4',
      type: 'color'
    },
    '700': {
      value: '#6438A3',
      type: 'color'
    },
    '800': {
      value: '#472672',
      type: 'color'
    },
    '900': {
      value: '#2C1845',
      type: 'color'
    },
    '950': {
      value: '#160C22',
      type: 'color'
    }
  },
  'form': {
    'field': {
      'shadow': {
        value: {
          "color": "#1212170d",
          "type": "dropShadow",
          "x": 0,
          "y": 1,
          "blur": 2,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'tabview': {
    'nav': {
      'button': {
        'shadow': {
          value: {
            "color": "#ffffff99",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 10,
            "spread": 50
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'message': {
    'info': {
      'shadow': {
        value: {
          "color": "#02050a0a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'success': {
      'shadow': {
        value: {
          "color": "#0108040a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'warn': {
      'shadow': {
        value: {
          "color": "#0907000a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'error': {
      'shadow': {
        value: {
          "color": "#0a03030a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'secondary': {
      'shadow': {
        value: {
          "color": "#0405060a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'contrast': {
      'shadow': {
        value: {
          "color": "#0000010a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    }
  },
  'toast': {
    'info': {
      'shadow': {
        value: {
          "color": "#02050a0a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'success': {
      'shadow': {
        value: {
          "color": "#0108040a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'warn': {
      'shadow': {
        value: {
          "color": "#0907000a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'error': {
      'shadow': {
        value: {
          "color": "#0a03030a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'secondary': {
      'shadow': {
        value: {
          "color": "#0405060a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'contrast': {
      'shadow': {
        value: {
          "color": "#0000010a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 8,
          "spread": 0
        },
        type: 'boxShadow'
      },
      'close': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    }
  },
  'tabs': {
    'nav': {
      'button': {
        'shadow': {
          value: {
            "color": "#ffffff99",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 10,
            "spread": 50
          },
          type: 'boxShadow'
        },
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'tab': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    },
    'tabpanel': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'focus': {
    'ring': {
      'shadow': {
        value: {
          "color": "#00000000",
          "type": "dropShadow",
          "x": 0,
          "y": 0,
          "blur": 0,
          "spread": 0
        },
        type: 'boxShadow'
      }
    }
  },
  'overlay': {
    'select': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'popover': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'modal': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 20,
          "blur": 25,
          "spread": -5
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 8,
          "blur": 10,
          "spread": -6
        }],
        type: 'boxShadow'
      }
    },
    'navigation': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'inputtext': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'button': {
    'raised': {
      'shadow': {
        value: [{
          "color": "#00000033",
          "type": "dropShadow",
          "x": 0,
          "y": 3,
          "blur": 1,
          "spread": -2
        }, {
          "color": "#00000024",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 2,
          "spread": 0
        }, {
          "color": "#0000001f",
          "type": "dropShadow",
          "x": 0,
          "y": 1,
          "blur": 5,
          "spread": 0
        }],
        type: 'boxShadow'
      }
    }
  },
  'toggleswitch': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'radiobutton': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'checkbox': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'autocomplete': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'dropdown': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'chip': {
    'remove': {
      'icon': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'card': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 3,
        "spread": 0
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": -1
      }],
      type: 'boxShadow'
    }
  },
  'breadcrumb': {
    'item': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'drawer': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 20,
        "blur": 25,
        "spread": -5
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 8,
        "blur": 10,
        "spread": -6
      }],
      type: 'boxShadow'
    }
  },
  'cascadeselect': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'datepicker': {
    'panel': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'dropdown': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    },
    'date': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'colorpicker': {
    'preview': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    },
    'panel': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'select': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'editor': {
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'textarea': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'knob': {
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'listbox': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    }
  },
  'multiselect': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'password': {
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'slider': {
    'handle': {
      'content': {
        'shadow': {
          value: [{
            "color": "#00000014",
            "type": "dropShadow",
            "x": 0,
            "y": 0.5,
            "blur": 0,
            "spread": 0
          }, {
            "color": "#00000024",
            "type": "dropShadow",
            "x": 0,
            "y": 1,
            "blur": 1,
            "spread": 0
          }],
          type: 'boxShadow'
        }
      },
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'togglebutton': {
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'content': {
      'shadow': {
        value: [{
          "color": "#00000005",
          "type": "dropShadow",
          "x": 0,
          "y": 1,
          "blur": 2,
          "spread": 0
        }, {
          "color": "#0000000a",
          "type": "dropShadow",
          "x": 0,
          "y": 1,
          "blur": 2,
          "spread": 0
        }],
        type: 'boxShadow'
      }
    }
  },
  'treeselect': {
    'shadow': {
      value: {
        "color": "#1212170d",
        "type": "dropShadow",
        "x": 0,
        "y": 1,
        "blur": 2,
        "spread": 0
      },
      type: 'boxShadow'
    },
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    },
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    }
  },
  'accordion': {
    'header': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'fieldset': {
    'legend': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'splitter': {
    'handle': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'scrollpanel': {
    'bar': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'confirmpopup': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'dialog': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 20,
        "blur": 25,
        "spread": -5
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 8,
        "blur": 10,
        "spread": -6
      }],
      type: 'boxShadow'
    }
  },
  'tooltip': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'contextmenu': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'dock': {
    'item': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'megamenu': {
    'overlay': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'mobile': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'menu': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'menubar': {
    'submenu': {
      'shadow': {
        value: [{
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 4,
          "blur": 6,
          "spread": -1
        }, {
          "color": "#0000001a",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 4,
          "spread": -2
        }],
        type: 'boxShadow'
      }
    },
    'mobile': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'tieredmenu': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'organizationchart': {
    'node': {
      'toggle': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    }
  },
  'paginator': {
    'nav': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'datatable': {
    'header': {
      'cell': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'row': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      },
      'toggle': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'filter': {
      'overlay': {
        'select': {
          'shadow': {
            value: [{
              "color": "#0000001a",
              "type": "dropShadow",
              "x": 0,
              "y": 4,
              "blur": 6,
              "spread": -1
            }, {
              "color": "#0000001a",
              "type": "dropShadow",
              "x": 0,
              "y": 2,
              "blur": 4,
              "spread": -2
            }],
            type: 'boxShadow'
          }
        },
        'popover': {
          'shadow': {
            value: [{
              "color": "#0000001a",
              "type": "dropShadow",
              "x": 0,
              "y": 4,
              "blur": 6,
              "spread": -1
            }, {
              "color": "#0000001a",
              "type": "dropShadow",
              "x": 0,
              "y": 2,
              "blur": 4,
              "spread": -2
            }],
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'timeline': {
    'event': {
      'marker': {
        'content': {
          'inset': {
            'shadow': {
              value: [{
                "color": "#0000000f",
                "type": "dropShadow",
                "x": 0,
                "y": 0.5,
                "blur": 0,
                "spread": 0
              }, {
                "color": "#0000001f",
                "type": "dropShadow",
                "x": 0,
                "y": 1,
                "blur": 1,
                "spread": 0
              }],
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'tree': {
    'node': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      },
      'toggle': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    }
  },
  'carousel': {
    'indicator': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'image': {
    'action': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'treetable': {
    'header': {
      'cell': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'row': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    },
    'node': {
      'toggle': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    }
  },
  'galleria': {
    'nav': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'thumbnail': {
      'nav': {
        'button': {
          'focus': {
            'ring': {
              'shadow': {
                value: {
                  "color": "#00000000",
                  "type": "dropShadow",
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0
                },
                type: 'boxShadow'
              }
            }
          }
        }
      }
    },
    'indicator': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    },
    'close': {
      'button': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      }
    }
  },
  'popover': {
    'shadow': {
      value: [{
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 4,
        "blur": 6,
        "spread": -1
      }, {
        "color": "#0000001a",
        "type": "dropShadow",
        "x": 0,
        "y": 2,
        "blur": 4,
        "spread": -2
      }],
      type: 'boxShadow'
    }
  },
  'splitbutton': {
    'raised': {
      'shadow': {
        value: [{
          "color": "#00000033",
          "type": "dropShadow",
          "x": 0,
          "y": 3,
          "blur": 1,
          "spread": -2
        }, {
          "color": "#00000024",
          "type": "dropShadow",
          "x": 0,
          "y": 2,
          "blur": 2,
          "spread": 0
        }, {
          "color": "#0000001f",
          "type": "innerShadow",
          "x": 0,
          "y": 1,
          "blur": 5,
          "spread": 0
        }],
        type: 'boxShadow'
      }
    }
  },
  'stepper': {
    'step': {
      'header': {
        'focus': {
          'ring': {
            'shadow': {
              value: {
                "color": "#00000000",
                "type": "dropShadow",
                "x": 0,
                "y": 0,
                "blur": 0,
                "spread": 0
              },
              type: 'boxShadow'
            }
          }
        }
      },
      'number': {
        'shadow': {
          value: [{
            "color": "#0000000f",
            "type": "dropShadow",
            "x": 0,
            "y": 0.5,
            "blur": 0,
            "spread": 0
          }, {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": 0,
            "y": 1,
            "blur": 1,
            "spread": 0
          }],
          type: 'boxShadow'
        }
      }
    }
  },
  'inplace': {
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  },
  'imagecompare': {
    'handle': {
      'focus': {
        'ring': {
          'shadow': {
            value: {
              "color": "#00000000",
              "type": "dropShadow",
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0
            },
            type: 'boxShadow'
          }
        }
      }
    }
  },
  'rating': {
    'focus': {
      'ring': {
        'shadow': {
          value: {
            "color": "#00000000",
            "type": "dropShadow",
            "x": 0,
            "y": 0,
            "blur": 0,
            "spread": 0
          },
          type: 'boxShadow'
        }
      }
    }
  }
};